import Modal from "react-modal";
import PantryLogo from "../../resources/pantry-icon-color.png";

// Style in JS due to quirks of 'react-modal'
const alertStyle = {
  content: {
    top: "50%",
    left: "50%",
    height: "fit-content",
    maxHeight: "80vh",
    width: "90%",
    maxWidth: "900px",
    padding: "20px 24px",
    transform: "translate(-50%, -50%)",
    borderRadius: "12px",
  },
};

/**

A React component for displaying an alert modal.
By default, the behavior requires users to click on the acknowledgement button in order to close the modal.
@param {Object} props - The component props.
@param {string} props.message - The main message of the alert.
@param {string} props.description - The detailed description of the alert (supports HTML).
@param {boolean} props.isOpen - Determines if the alert modal is open or closed.
@param {Function} props.setParentOpen - Callback function to update the open state of the parent component.
@param {Function} props.onAcknowledge - Callback function to handle the acknowledge button click.
@returns {JSX.Element} The alert modal component.
*/
export default function Alert({
  message,
  description,
  isOpen,
  setParentOpen,
  onAcknowledge,
  ...args
}) {
  function onCloseAlert() {
    setParentOpen(false);
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseAlert}
      style={alertStyle}
      contentLabel="Pantry Alert"
      shouldCloseOnOverlayClick={false}
      {...args}
    >
      <span className="alert-header">
        <img src={PantryLogo} alt="Pantry" height="100" />
      </span>
      <div className="alert-content">
        <p className="text-danger h5">{message}</p>
        <p dangerouslySetInnerHTML={{ __html: description }}></p>
        <div>
          <button className="btn btn-warning" onClick={onAcknowledge}>
            I understand
          </button>
        </div>
      </div>
    </Modal>
  );
}
