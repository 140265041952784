import { useEffect, useState } from "react";
import { useParams } from "react-router";
import StaffService from "../../../../services/staff.service";
import Multiselect from "multiselect-react-dropdown";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "./item-form.css";
import {
  CATEGORIES,
  EXTENDED_ALLERGENS,
  STORAGE_TYPES,
} from "../../../../constants";

function checkEmptyImage(src) {
  if (src instanceof Blob) return false;
  return src.split("/")[src.split("/").length - 1] !== "None";
}

function dateFunc(new_amount, prev_amount, prev_date, restock) {
  if (new_amount > prev_amount && restock) {
    return new Date().toISOString();
  } else {
    return new Date(prev_date).toISOString();
  }
}

/**
 * @description Form used for add items. Turns into edit if an id is provided in the url params
 * @returns JSX.Element
 */
export default function ItemForm() {
  const params = useParams();
  const editModeItemId = params.id;
  const editMode = editModeItemId !== undefined;
  const [loading, setLoading] = useState(editMode);
  const [sending, isSending] = useState(false);

  const [formItem, setFormItem] = useState({
    itemName: "",
    incrementAmount: 0,
    amount: 1,
    threshold: 1,
    limit: 1,
    storageType: "SF",
    allergen: ["NO"],
    categories: ["UN"],
    item_description: "",

    newDate: new Date().toISOString(),
    restock: false,
    image: null,
    canOrder: true,

    // Edit-only states
    prev_amount: 1,
    prev_date: new Date().toISOString(),
    imageLink: "",
  });

  const setFormItemKey = (key, newValue) => {
    setFormItem((oldFormItem) => {
      return {
        ...oldFormItem,
        [key]: newValue,
      };
    });
  };

  // return to previous page
  const goBack = () => {
    window.history.back();
  };

  // Load item data for prefill only in edit mode
  useEffect(() => {
    if (!editMode) return;

    // Fetch item from id
    StaffService.fetchItem(params.id)
      .then((item) => {
        // Clone item data and remove unused properties
        const itemClone = { ...item };
        delete itemClone.id;
        delete itemClone.display;
        delete itemClone.date_added;

        // Property renames
        itemClone.itemName = itemClone.name;
        delete itemClone.name;
        itemClone.storageType = itemClone.storage_type;
        delete itemClone.storage_type;
        itemClone.canOrder = itemClone.can_order;
        delete itemClone.can_order;

        // Property clones
        itemClone.newDate = itemClone.timestamp;
        itemClone.imageLink = itemClone.image;
        itemClone.prev_amount = itemClone.amount;
        itemClone.prev_date = itemClone.timestamp;

        // Load into state
        setFormItem((old) => ({ ...old, ...itemClone }));
      })
      .finally(setLoading(false));
  }, [editMode, params.id]);

  const setSelectedAllergens = (selected) => {
    setFormItemKey(
      "allergen",
      selected.map((select) => select.value)
    );
  };

  const setSelectedCategories = (selected) => {
    setFormItemKey(
      "categories",
      selected.map((select) => select.value)
    );
  };

  const onSubmit = () => {
    const data = new FormData();
    [
      "itemName",
      "amount",
      "threshold",
      "limit",
      "storageType",
      "allergen",
      "canOrder",
      "categories",
      "timestamp",
      "item_description",
    ].forEach((key) => data.append(key, formItem[key]));

    // Image replace/delete flags
    if (formItem.image && formItem.image instanceof Blob) {
      data.append("image", formItem.image, formItem.image.name);
      data.append("update_image", true);
      data.append("delete_image", false);
    } else if (formItem.imageLink !== "") {
      data.append("update_image", false);
      data.append("delete_image", false);
    } else {
      data.append("image", "None");
      data.append("delete_image", true);
      data.append("update_image", false);
    }

    if (formItem.itemName === "") {
      alert("Please provide an item name");
    } else if (
      formItem.canOrder === true &&
      parseInt(formItem.amount) < parseInt(formItem.threshold)
    ) {
      alert(
        "Item should not be order-able if quantity is less than the threshold. Check fields again."
      );
    } else {
      if (editMode) {
        isSending(true);
        StaffService.editItem(data, params.id)
          .then((response) => {
            if (response.status === 200) {
              // Success
              window.location.pathname = "/staff";
            } else if (response.status === 400) {
              // Client error
              alert(response.data);
            } else if (response.status === 500) {
              // Server error
              alert(response.data);
            }

            isSending(false);
          })
          .catch((err) => {
            console.err(err);
            isSending(false);
          });
      } else {
        isSending(true);
        StaffService.addItem(data)
          .then((response) => {
            if (response.status === 200) {
              // Success
              window.location.pathname = "/staff";
            } else if (response.status === 400) {
              // Client error
              alert(response.data);
            } else if (response.status === 500) {
              // Server error
              alert(response.data);
            }

            isSending(false);
          })
          .catch((err) => {
            console.err(err);
            isSending(false);
          });
      }
    }
  };

  if (loading) {
    return <h2 className="text-center">Loading edit...</h2>;
  }

  return (
    <>
      <h2 className="text-center">{!editMode ? "Add" : "Edit"} Item</h2>
      <div className="page-content">
        <div className="containers">
          <div className="item-info">
          <div className="header">Item Basic Information</div>
          <div className="item-info-content">
          <div className="Name">
              <label>Item Name *</label>
              <input
                type="text"
                placeholder="Required"
                className="form-control input-lg"
                value={formItem.itemName}
                onChange={(e) => {
                  setFormItemKey("itemName", e.target.value);
                }}
              />
            </div>
            <div className="Description">
            <label>Item Description</label>
              <textarea
                className="form-control input-lg"
                placeholder="Description:"
                style={{ wordWrap: "break-word" }}
                value={formItem.item_description}
                onChange={(e) => {
                  setFormItemKey("item_description", e.target.value);
                }}
                maxLength={200}
              />
              <div>
                {formItem.item_description.length}/{200}
              </div>
            </div>
            {/* Image upload preview functions */}
            <div className="form-group">
              <div>
                <label htmlFor="formFile" className="form-label">
                  Image
                </label>
                <input
                  className="form-control"
                  type="file"
                  id="formFile"
                  onChange={(e) => {
                    // console.table(e.target);
                    setFormItemKey("image", e.target.files[0]);
                    setFormItemKey(
                      "imageLink",
                      URL.createObjectURL(e.target.files[0])
                    );
                  }}
                />
                <div>
                  {formItem.imageLink && checkEmptyImage(formItem.imageLink) && (
                    <img
                      id="frame"
                      alt="Item File Preview"
                      src={formItem.imageLink}
                      width="300"
                      height="auto"
                    />
                  )}
                </div>
                <button
                  onClick={() => {
                    setFormItemKey("image", null);
                    setFormItemKey("imageLink", "");
                  }}
                  className="btn btn-primary mt-2"
                >
                  Remove
                </button>
              </div>
            </div>
          </div>
          </div>

          <div className="item-details">
          <div className="header">Item Details</div>
          <div className="item-details-content">
          <div className="form-group row flex-wrap">
              <div className="col-5 flex-shrink-0" style={{ marginRight: "-20px" }}>
                <label>Amount</label>
                <input
                  type="number"
                  min="1"
                  placeholder="Enter value"
                  className="form-control input-lg"
                  value={formItem.amount}
                  onChange={(e) => {
                    setFormItemKey(
                      "newDate",
                      dateFunc(
                        e.target.value,
                        formItem.prev_amount,
                        formItem.prev_date,
                        formItem.restock
                      )
                    );
                    setFormItemKey("amount", e.target.value);
                  }}
                />
              </div>
              <div className="col-5">
                <label className="text-nowrap">Increment Value</label>
                <input
                  type="number"
                  placeholder="Enter value"
                  className="form-control input-lg"
                  value={formItem.incrementAmount}
                  onChange={(e) => {
                    setFormItemKey("incrementAmount", e.target.value);
                  }}
                />
              </div>
            </div>
            <button
              onClick={() => {
                setFormItemKey(
                  "amount",
                  parseInt(formItem.amount) + parseInt(formItem.incrementAmount)
                );
              }}
              className="btn btn-primary mt-2"
            >
              Increase by Increment Value
            </button>
            <div className="form-group">
              <label>Threshold</label>
              <input
                type="number"
                min="1"
                placeholder="Enter threshold"
                className="form-control"
                value={formItem.threshold}
                onChange={(e) => {
                  setFormItemKey("threshold", e.target.value);
                }}
              ></input>
            </div>
            <div className="form-group">
              <label>Limit</label>
              <input
                type="number"
                min="1"
                placeholder="Enter limit"
                className="form-control"
                value={formItem.limit}
                onChange={(e) => {
                  setFormItemKey("limit", e.target.value);
                }}
              ></input>
            </div>
            <div className="form-group form-check mt-2">
              <label htmlFor="can-order" className="mt-0">
                <input
                  type="checkbox"
                  id="can-order"
                  name="can-order"
                  className="form-check-input"
                  checked={formItem.canOrder}
                  onChange={(e) => {
                    setFormItemKey("canOrder", e.target.checked);
                  }}
                />
                Can Order
              </label>
            </div>
            <div className="form-group">
              <label>Storage Type</label>
              <select
                className="form-select"
                value={formItem.storageType}
                onChange={(e) => {
                  setFormItemKey("storageType", e.target.value);
                }}
              >
                {STORAGE_TYPES.map((storageType, idx) => (
                  <option value={storageType.value} key={idx}>
                    {storageType.handle}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group mb-2">
              <label>Categories</label>
              <Multiselect
                placeholder="Categories:"
                options={CATEGORIES}
                displayValue="handle"
                onSelect={setSelectedCategories}
                onRemove={setSelectedCategories}
                selectedValues={CATEGORIES.filter((categoryOption) =>
                  formItem.categories.includes(categoryOption.value)
                )}
              />
            </div>

            <div className="form-group">
              <label>Allergen</label>
              <Multiselect
                placeholder="Filters:"
                options={EXTENDED_ALLERGENS}
                displayValue="handle"
                onSelect={setSelectedAllergens}
                onRemove={setSelectedAllergens}
                selectedValues={EXTENDED_ALLERGENS.filter((allergenOption) =>
                  formItem.allergen.includes(allergenOption.value)
                )}
              />
            </div>
            </div>
          </div>
          </div>
          <div className="button-container">
            <button
              className="btn btn-primary mt-5"
              onClick={() => {
                confirmAlert({
                  customUI: ({ onClose }) => {
                    return (
                      <div className="ui-modal-primary">
                        <h1>Are you sure?</h1>
                        <span className="d-flex gap-2">
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              onSubmit();
                              onClose();
                            }}
                          >
                            Confirm
                          </button>
                          <button className="btn btn-secondary" onClick={onClose}>
                            Cancel
                          </button>
                        </span>
                      </div>
                    );
                  },
                });
              }}
              disabled={sending}
            >
              {!editMode ? "Add " : "Edit "} Item{" "}
              {sending && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
            </button>
            <button className="mt-5 btn btn-danger" onClick={goBack}>
              Cancel
            </button>
          </div>
      </div>
    </>
  );
}
