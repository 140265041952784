import { AddTimeslotForm } from "../../sections/timeslot-form";
import { useState } from "react";

/**
 * Sends a POST to server containing new timeslot to add
 * @returns JSX.Element
 */
export default function AddTimeslot() {
  const [date, setDate] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [capacity, setCapacity] = useState(10);
  const [display, setDisplay] = useState(true);

  return (
    <AddTimeslotForm
      date={date}
      setDate={setDate}
      startTime={startTime}
      setStartTime={setStartTime}
      endTime={endTime}
      setEndTime={setEndTime}
      capacity={capacity}
      setCapacity={setCapacity}
      display={display}
      setDisplay={setDisplay}
    />
  );
}
