import Modal from "react-modal";
import { AiOutlineWarning } from "react-icons/ai";
import { MdOutlineFiberNew } from "react-icons/md";
import dateParser from "../../../../helpers/date-parser";
import { useState } from "react";

const inventoryStyle = {
  content: {
    top: "50%",
    left: "50%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

/**
 * @description Constructs inventory modal for order page
 * @returns JSX.Element
 */
export default function InventoryModal({
  showingTable,
  setShowingTable,
  addNewItem,
  orderProducts,
  inventory,
}) {
  function onClose() {
    setShowingTable(false);
  }

  // Sorts inventory by amount
  const sortedInventory = [...inventory].sort((a, b) => b.amount - a.amount);
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 5;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedInventory.slice(indexOfFirstItem, indexOfLastItem);


  return (
    <Modal
      isOpen={showingTable}
      onRequestClose={onClose}
      style={inventoryStyle}
      contentLabel="Inventory"
      ariaHideApp={false}
    >
      <button className="btn btn-danger" onClick={onClose}>
        X
      </button>
      <section className="table-responsive overflow-auto">
        <table className="table caption-top table-hover">
          <caption>Select an item to add</caption>
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Name</th>
              <th scope="col">Amount</th>
              <th scope="col">Limit</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {currentItems &&
              currentItems.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    {!item.can_order ? (
                      <td className="text-danger">
                        <AiOutlineWarning /> {item.name}
                      </td>
                    ) : dateParser(item.date_added) ? (
                      <td className="text-success">
                        <MdOutlineFiberNew size={20} /> {item.name}
                      </td>
                    ) : (
                      <td>{item.name}</td>
                    )}
                    <td>{item.amount}</td>
                    <td>{item.limit}</td>
                    <td>
                      {!item.can_order ||
                      orderProducts.findIndex(
                        (i) => i.item_name === item.name
                      ) !== -1 ? (
                        <button className="btn btn-danger" disabled>
                          Add
                        </button>
                      ) : (
                        <button
                          className="btn btn-success"
                          onClick={() => {
                            if (
                              orderProducts.findIndex(
                                (i) => i.item_name === item.name
                              ) !== -1
                            ) {
                              window.alert("Item already in order.");
                            } else {
                              addNewItem({
                                item_name: item.name,
                                quantity: 1,
                                item: item.id,
                              });
                            }
                          }}
                        >
                          Add
                        </button>
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </section>
      <div className="d-flex gap-2 border-bottom-0 justify-content-center align-items-center">
            <button
                className="btn btn-secondary"
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={currentPage === 1}
            >
                Previous
            </button>
            <span>Page {currentPage} of {Math.ceil(sortedInventory.length / itemsPerPage)}</span>
            <button
                className="btn btn-secondary"
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={currentPage === Math.ceil(sortedInventory.length / itemsPerPage)}
            >
                Next
            </button>
          </div>
    </Modal>
  );
}
