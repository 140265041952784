import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import AppDefaultFrame from "./AppFrames";
import Http404 from "../pages/404/404";
import Home from "../pages/home/home";
import Login from "../pages/google-login";
import SignIn from "../pages/sign-in/sign-in";
import Profile from "../pages/profile";
import SchedulingForm from "../pages/timeslot";
import Order from "../pages/order/order";
import BoardStaff from "../pages/staff/inventory/inventory";
import StaffOrder from "../pages/staff/staff-order/staff-order";
import StaffTimeslot from "../pages/staff/staff-timeslot";
import AddTimeslot from "../pages/staff/add-timeslot";
import AddOrder from "../pages/staff/add-order/add-order";
import EditTimeslot from "../pages/staff/edit-timeslot";
import EditOrder from "../pages/staff/edit-order/edit-order";
import VolunteerEditOrder from "../pages/staff/volunteer-edit-order/volunteer-edit-order";
import CancelOrder from "../pages/cancel-order";
import FulfillOrders from "../pages/staff/fulfill-orders/fulfill-orders";
import AuthWall from "../parts/authwall/AuthWall";
import ItemForm from "../pages/staff/add-edit-item/item-form";
import AbbyrlFools from "../pages/abbyrlFools";

export default function AppRouter() {
  return (
    <Routes>
      {/* Public Pages (Centered): All routes in here are accessible by anyone and have a centered logo */}
      <Route
        element={<AppDefaultFrame centerLogo={true} children={<Outlet />} />}
      >
        <Route exact path="" element={<Home />} />
        <Route exact path="home" element={<Home />} />
        <Route exact path="/start" element={<AbbyrlFools />} />
      </Route>

      {/* Normal Pages: All routes in here are accessible by anyone and have a left-corner-aligned logo */}
      <Route element={<AppDefaultFrame children={<Outlet />} />}>
        <Route path="google/*" element={<Login />} />
        <Route exact path="sign-in" element={<SignIn />} />
        <Route path="order" element={<SchedulingForm />} />
        <Route path="select-items/:timeslotId" element={<Order />} />
        <Route path="select-items" element={<SchedulingForm />} />
        <Route path="cancel-order/:id" element={<CancelOrder />} />
      </Route>

      {/* Patron Pages: All routes in here are accessible by anyone logged in and have a left-corner-aligned logo */}
      <Route
        element={
          <AuthWall allowRoles={["Patron", "Volunteer", "Staff"]}>
            <AppDefaultFrame children={<Outlet />} />
          </AuthWall>
        }
      >
        <Route exact path="profile" element={<Profile />} />
      </Route>

      {/* Staff Pages: All routes in here are only accessible by staff and volunteers and have an auth wall applied to them */}
      <Route path="staff">
        {/* Staff AND Volunteer Pages */}
        <Route
          element={
            <AuthWall allowRoles={["Staff", "Volunteer"]}>
              <AppDefaultFrame children={<Outlet />} />
            </AuthWall>
          }
        >
          <Route path="timeslot" element={<StaffTimeslot />} />
          <Route path="order" element={<StaffOrder />} />
          <Route path="add-order" element={<AddOrder />} />
          <Route path="edit-order/:id" element={<EditOrder />} />
          <Route
            path="volunteer-edit-order/:id"
            element={<VolunteerEditOrder />}
          />
          <Route path="fulfill-orders" element={<FulfillOrders />} />
        </Route>

        {/* Staff ONLY Pages */}
        <Route
          element={
            <AuthWall allowRoles={["Staff"]}>
              <AppDefaultFrame children={<Outlet />} />
            </AuthWall>
          }
        >
          <Route exact path="" element={<BoardStaff />} />
          <Route path="add-item" element={<ItemForm />} />
          <Route path="edit-item/:id" element={<ItemForm />} />
          <Route path="add-timeslot" element={<AddTimeslot />} />
          <Route path="edit-timeslot/:id" element={<EditTimeslot />} />
        </Route>
      </Route>

      {/* 404 Page: The default page when the url matches nothing */}
      <Route path="*" element={<Http404 />} />
    </Routes>
  );
}
