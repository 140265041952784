import { useEffect, useRef, useState } from "react";
import PublicApiService from "../../../services/public-api.service";
import UserService from "../../../services/user.service";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { useCart } from "react-use-cart";
import CartModal from "../../parts/CartModal";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import LegendModal from "../../units/allergen-modal";
import Alert from "../../parts/alert";
import PantryButton from "../../parts/PantryButton";
import { OrderTable } from "./order-table";
import { ALLERGENS, CATEGORIES } from "../../../constants";
import Loading from "../../parts/Loading";
import useAuth from "../../../helpers/useAuth";
import PageSelector from "../../parts/PageSelector";
import "./order.css";

/**
 * Attempts to load timeslot data from localStorage
 * @return {Object | null} Converts data into JSON if possible. Else returns null
 */
function loadStoredTimeslotData() {
  let storedTimeslotData = localStorage.getItem("selectedData");
  if (!storedTimeslotData) return null;
  try {
    return JSON.parse(storedTimeslotData);
  } catch (err) {
    return null;
  }
}

/**
 * @description Construct order page containing inventory data. Fetches inventory
 * asynchronously upon page load.
 *
 * maxPerPage, inventory, page, and leftPage states are all used to determine the page count
 * for pagination.
 *
 * The table is generated dynamically based on the fetched data from the API and adjusted
 * according to the filter options and page count.
 *
 * The cart hook is used to store the user's item which defines all the functions utilized
 * to ensure the functionality of the ordering page.
 * @returns JSX.Element
 */
export default function Order() {
  const { timeslotId } = useParams();
  const { current: savedTimeslotData } = useRef(loadStoredTimeslotData());
  const [timeslotText, setTimeslotText] = useState(
    savedTimeslotData && savedTimeslotData.id === timeslotId
      ? savedTimeslotData.text
      : "[Loading Details...]"
  );

  const navigate = useNavigate();

  useEffect(() => {
    PublicApiService.getSingleTimeslot(timeslotId).then((timeslot) => {

      const startTime = new Date(timeslot.start_time);
      const currentTime = new Date();
      
      // Check if the start_time is in the past
      if (startTime < currentTime) {
        navigate("/404");
        throw new Error("Timeslot start time is in the past");
      }

      const dayStr = new Date(timeslot.start_time).toLocaleDateString("en-us", {
        weekday: "long",
        month: "long",
        day: "numeric",
      });

      const startTimeStr = new Date(timeslot.start_time).toLocaleTimeString(
        "en-US",
        {
          timeStyle: "short",
        }
      );
      const endTimeStr = new Date(timeslot.end_time).toLocaleTimeString(
        "en-US",
        {
          timeStyle: "short",
        }
      );
      setTimeslotText(`${dayStr} ${startTimeStr} - ${endTimeStr}`);
    })
    .catch((err) => {
      if (err.response && err.response.status === 404) {
        navigate("/404");
      } else {
        console.error(err); // Handle other errors
      }
    });
  }, [navigate, timeslotId]);

  const { data: user } = useAuth();
  const [email, setEmail] = useState(user?.email || "");
  useEffect(() => setEmail(user?.email || ""), [user?.email]);

  const [inventory, setInventory] = useState([]);
  const { emptyCart, isEmpty } = useCart();
  const [searchItem, setSearchItem] = useState("");
  const [searchFilter, setSearchFilter] = useState([]);
  const [searchCategory, setSearchCategory] = useState([]);
  const [alert, setAlert] = useState(null);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [maxPerPage, setMaxPerPage] = useState(10);
  const [loading, isLoading] = useState(true);

  // For pagination
  const [page, setPage] = useState(1);

  useEffect(() => {
    const callback = (e) => {
      if (!isEmpty) {
        // Warns user on refresh
        e.preventDefault();
        e.returnValue = "";
        return "";
      }
    };
    window.addEventListener("beforeunload", callback);
    return () => window.removeEventListener("beforeunload", callback);
  }, [isEmpty]);

  // On component mount
  useEffect(() => {
    // TODO: In the future, don't empty entire cart and only remove what is now out of stock
    emptyCart();

    UserService.getPatronBoard() // Fetch + set inventory items
      .then((res) => {
        setInventory(res.data);
        isLoading(false); // Unset loading state
      })
      .catch(console.error)
      .finally(() => isLoading(false));
  }, []);

  setCount(inventory);

  function onCloseAlert() {
    setIsAlertOpen(false);
    navigate("/order");
  }

  function checkCategory(item) {
    const itemCategories = item.categories; // item's categories (array)
    // searchCategory = array of categories want to search by

    // if no searchCategory then return everything
    if (searchCategory.length === 0) return true;
    // if there is a searchCategory
    else {
      // go through searchCategory
      for (let i = 0; i < searchCategory.length; i++) {
        // itemCategories.indexOf(searchCategory[i]) -> looks through the categories
        // of the item and sees if one of the searchCategories is in it

        // ex: tomato = VG, VE, KO, HA = itemCategories
        // searchCategory = VG, KO

        // if tomato contains both VG and KO then true else false
        if (itemCategories.indexOf(searchCategory[i]) === -1) return false;
      }
      return true;
    }
  }

  // will need to be changed most likely
  // goes through every item twice and then every item's allergens and categories
  // maybe do a SQL query instead
  function setCount(inventory) {
    inventory.forEach((item) => {
      if (
        !item.can_order ||
        item.amount < item.threshold ||
        item.categories === "UN" ||
        item.allergen === "UN"
      ) {
        return;
      }
      item.categories.forEach((categoryVal) => {
        const categoryIndex = CATEGORIES.findIndex(
          (category) => category.value === categoryVal
        );
        if (categoryIndex !== -1 && !CATEGORIES[categoryIndex].set) {
          CATEGORIES[categoryIndex].count += 1;
        }
      });

      item.allergen.forEach((allergenVal) => {
        const allergenIndex = ALLERGENS.findIndex(
          (allergen) => allergen.value === allergenVal
        );
        if (allergenIndex !== -1 && !ALLERGENS[allergenIndex].set) {
          ALLERGENS[allergenIndex].count += 1;
        }
      });
    });

    CATEGORIES.forEach((category) => {
      // category.handle = `${category.handle}: ${category.count}`;
      category.handle = `${category.handle.split(":")[0]}: ${category.count}`;
      if (category.count !== 0) {
        category.set = true;
      }
    });

    ALLERGENS.forEach((allergen) => {
      allergen.handle = `${allergen.handle.split(":")[0]}: ${allergen.count}`;
      if (allergen.count !== 0) {
        allergen.set = true;
      }
    });
  }

  // sorts allergens by quantity
  const sortedAllergens = ALLERGENS.sort((a, b) => b.count - a.count);

  // function filterColor() {
  //   ALLERGENS.forEach((allergen) => {
  //     if (allergen.count > 0) {
  //       return {color: 'red'}
  //     }
  //     return {color: 'grey'}
  //   })
  // }

  function checkFilter(item) {
    const itemFilters = item.allergen; // item's allergens (array)
    if (searchFilter.length === 0) return true;

    for (let i = 0; i < itemFilters.length; i++) {
      if (searchFilter.indexOf(itemFilters[i]) === -1) return true;
    }

    return false;
  }

  function checkThreshold(item) {
    if (item.amount > item.threshold) return item;
  }

  const addFilter = (e) => {
    setSearchFilter([
      ...searchFilter,
      e[searchFilter.length] && e[searchFilter.length].value,
    ]);
    setPage(1);
  };

  const deleteFilter = (e) => {
    const tmp_array = [];
    for (let i = 0; i < e.length; i++) {
      tmp_array.push(e[i] && e[i].value);
    }
    setSearchFilter(tmp_array);
  };

  const addCategory = (e) => {
    setSearchCategory([
      ...searchCategory,
      e[searchCategory.length] && e[searchCategory.length].value,
    ]);
    setPage(1);
  };

  const deleteCategory = (e) => {
    const tmp_array = [];
    for (let i = 0; i < e.length; i++) {
      tmp_array.push(e[i] && e[i].value);
    }
    setSearchCategory(tmp_array);
  };

  function onPageDecrement() {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  }

  function onPageIncrement() {
    if (page < Math.ceil(filteredInventory.length / maxPerPage)) {
      setPage((prevPage) => prevPage + 1);
    }
  }

  const filteredInventory = [...inventory]
    .filter((item) => {
      // Filter items for searching
      return (
        (!searchItem && item) ||
        item.name.toLowerCase().includes(searchItem.toLowerCase())
      );
    })
    .filter(checkCategory)
    .filter(checkFilter)
    .filter(checkThreshold)
    .filter((item) => item.can_order)
    .filter((item) => {
      // Remove allergens
      if (item.allergen.some((allergen) => searchFilter.includes(allergen)))
        return false;

      // Include by category if any are selected
      return (
        searchCategory.length === 0 ||
        item.categories.some((category) => searchCategory.includes(category))
      );
    });

  return (
    <>
      <Alert
        message={alert}
        isOpen={isAlertOpen}
        setParentOpen={setIsAlertOpen}
        onAcknowledge={onCloseAlert}
      />

      <div className="d-flex justify-content-between align-items-center order-top-interact overlay">
        <PantryButton
          className="mt-2 mb-3 mt-md-0 mb-md-0"
          onClick={() => {
            navigate("/order");
          }}
        >
          Back
        </PantryButton>

        <CartModal
          timeslotId={timeslotId}
          timeslotText={timeslotText}
          id="cart"
          email={email}
        />
      </div>

      <div className="order-info">
        <h2 className="text-center">Your Order</h2>
        <p className="">
          Scheduled for: <b>{timeslotText}</b>
        </p>
        <p className="reuse-bag">Please bring reusable bags if you can!</p>
        <p className="">
          Perishable foods are served on-site every weekday and cannot be
          pre-ordered.
        </p>
        <p className="">
          Please order non-perishables a day in advance and check the email
          receipt you receive.
        </p>
        {/* <EmailContainer
          email={email}
          setInput={setEmail}
          onConfirm={() => {
            let result = new RegExp(
              "^[A-Za-z0-9._%+-]+(@ucdavis.edu|@thepantry.ucdavis.edu)$"
            ).test(email);
            if (result) {
              setAlert(
                <div
                  className="alert alert-success alert-dismissible fade show mt-2"
                  role="alert"
                >
                  Your email has successfully been set to{" "}
                  <span className="alert-link">{email}</span>!
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                    onClick={() => setAlert(null)}
                  ></button>
                </div>
              );
            } else {
              setEmail("");
              setAlert(
                <div
                  className="alert alert-danger alert-dismissible fade show mt-2"
                  role="alert"
                >
                  Please enter a valid UC Davis email address!
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                    onClick={() => setAlert(null)}
                  ></button>
                </div>
              );
            }
          }}
          disabled={userVerified}
        >
          {alert}
        </EmailContainer> */}
      </div>

      <div className="category-search-container">
        <div className="category-container">
          <div className="form-group mb-2">
            <Multiselect
              placeholder="Categories:"
              options={CATEGORIES}
              displayValue="handle"
              onSelect={addCategory}
              onRemove={deleteCategory}
            />
          </div>

          <div className="form-group mb-2">
            <Multiselect
              placeholder="Excludes:"
              options={sortedAllergens}
              displayValue="handle"
              onSelect={addFilter}
              onRemove={deleteFilter}
              // style={{
              //   multiselectContainer: filterColor()
              // }}
            />
          </div>
        </div>

        <div className="search-container">
          {/*<label for="search">
           <strong>Search:</strong>
           </label>*/}
          <div>
            <input
              id="search"
              className="form-control"
              type="text"
              placeholder="Find an item"
              onChange={(e) => setSearchItem(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="bottom-interact-container">
        <span>
          Show entries:
          <select
            className="form-select"
            onChange={(e) => {
              setMaxPerPage(e.target.value);
            }}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          {/*<LegendModal />*/}
        </span>
      </div>
      <div id="allergen-modal">
        <LegendModal />
      </div>
      <OrderTable
        filteredDisplayInventory={filteredInventory}
        page={page}
        maxPerPage={maxPerPage}
        loading={loading}
      />
      {loading ? <Loading /> : null}
      <div className="pagination-container">
        <div className="arrow-container">
          <PantryButton onClick={onPageDecrement}>
            <AiOutlineArrowLeft />
          </PantryButton>
          <PantryButton onClick={onPageIncrement}>
            <AiOutlineArrowRight />
          </PantryButton>
        </div>

        <div>
          <PageSelector
            pageLimit={Math.ceil(filteredInventory.length / maxPerPage)}
            currentPage={page}
            setPage={setPage}
            showSearch={false}
          />
        </div>
      </div>
    </>
  );
}
