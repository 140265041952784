import Modal from "react-modal";
import { useState } from "react";

const itemModalStyle = {
  content: {
    top: "50%",
    left: "50%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

/**
 * @description Constructs a modal for items in the orders page
 * @returns JSX.Element
 */
export default function ItemModal({ items }) {
  const [itemModalOpen, setModalOpen] = useState(false);

  function openItemModal() {
    setModalOpen(true);
  }

  function closeItemModal() {
    setModalOpen(false);
  }

  return (
    <div>
      <button className="btn btn-primary" onClick={openItemModal}>
        View
      </button>
      <Modal
        isOpen={itemModalOpen}
        onRequestClose={closeItemModal}
        style={itemModalStyle}
        contentLabel="Order Items"
      >
        <div className="modal-header">
          <h4 className="food-bag">Items</h4>
          <button className="btn btn-danger" onClick={closeItemModal}>
            X
          </button>
        </div>

        <section className="table-responsive ">
          <table className="table caption-top table-sm">
            <caption>Order content</caption>
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Name</th>
                <th scope="col">Quantity</th>
              </tr>
            </thead>
            <tbody>
              {items &&
                items.map((product, idx) => {
                  return (
                    <tr key={idx}>
                      <td className="col-md-1">{product.item}</td>
                      <td>{product.item_name}</td>
                      <td>{product.quantity}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </section>
      </Modal>
    </div>
  );
}
