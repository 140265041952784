import { useState } from "react";
import { useNavigate } from "react-router";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { confirmAlert } from "react-confirm-alert";
import { setHours, setMinutes } from "date-fns";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { HiChevronUp, HiChevronDown, HiSelector } from "react-icons/hi";
import Loading from "../../parts/Loading";
import PantryButton from "../../parts/PantryButton";
import StaffService from "../../../services/staff.service";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-datepicker/dist/react-datepicker.css";
import "./staff-timeslot.css";
import { useTimeslots } from "../../../helpers/publicAPIHooks";
import PageSelector from "../../parts/PageSelector";

/* Search filter options */
const filterOptions = [
  { value: "Date", label: "Date" },
  { value: "Start Time", label: "Start Time" },
  { value: "End Time", label: "End Time" },
];

/* Table filter options */
const sortOptions = {
  date: "date",
  capacity: "capacity",
  startTime: "startTime",
  endTime: "endTime",
};

export default function StaffTimeslot() {
  const { timeslots, loading, reloadTimeslots } = useTimeslots();
  const [selectedOption, setSelectedOption] = useState(filterOptions[0]);
  const [startDate, setStartDate] = useState(null);

  // Table filter hooks
  const [sortAscending, setSortAscending] = useState(false);
  const [activeSort, setActiveSort] = useState(sortOptions.date);

  // Pagination hooks
  const [page, setPage] = useState(1);
  const [maxPerPage, setMaxPerPage] = useState(10);

  const navigate = useNavigate();

  const checkFilter = (ts) => {
    if (startDate === null) {
      return true;
    }

    switch (selectedOption.value) {
      case "Start Time":
        if (
          new Date(ts.start_time).toLocaleTimeString() ===
          startDate.toLocaleTimeString()
        ) {
          return true;
        }
        break;

      case "End Time":
        if (
          new Date(ts.end_time).toLocaleTimeString() ===
          startDate.toLocaleTimeString()
        ) {
          return true;
        }
        break;

      case "Date":
      default:
        if (
          new Date(ts.start_time).toLocaleDateString() ===
          startDate.toLocaleDateString()
        ) {
          return true;
        }
        break;
    }
  };

  const deleteTimeslot = (timeslotID) => {
    StaffService.deleteTimeslot(timeslotID).then(() => reloadTimeslots());
  };

  const toggleSort = (option) => {
    if (activeSort === option) {
      setSortAscending(!sortAscending);
      return;
    }
    setActiveSort(option);
    setSortAscending(true);
  };

  /* Sorting for table's filter arrows */
  function sortFunc(a, b) {
    let valueA, valueB, timeA, timeB;
    switch (activeSort) {
      case sortOptions.capacity: // Sort by capacity
        valueA = a.current_capacity;
        valueB = b.current_capacity;
        return sortAscending ? valueA - valueB : valueB - valueA;
      case sortOptions.startTime: // Sort by start time
        timeA = new Date(a.start_time).toTimeString();
        timeB = new Date(b.start_time).toTimeString();
        return sortAscending ? timeA < timeB : timeB < timeA;
      case sortOptions.endTime: // Sort by end time
        timeA = new Date(a.end_time).toTimeString();
        timeB = new Date(b.end_time).toTimeString();
        return sortAscending ? timeA < timeB : timeB < timeA;
      case sortOptions.date: // Sort by date
        timeA = new Date(a.start_time).getTime();
        timeB = new Date(b.start_time).getTime();
        return sortAscending ? timeA - timeB : timeB - timeA;
      default: // Set default case as sorting by date
        timeA = new Date(a.start_time).getTime();
        timeB = new Date(b.start_time).getTime();
        return sortAscending ? timeA - timeB : timeB - timeA;
    }
  }

  function onPageDecrement() {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  }

  function onPageIncrement() {
    if (page < Math.ceil(timeslots.length / maxPerPage)) {
      setPage((prevPage) => prevPage + 1);
    }
  }

  return (
    <>
      <h1 className="text-center" id="top-of-page">
        Timeslots
      </h1>
      <div className="d-flex justify-content-between align-items-center">
        <span className="d-flex gap-2">
          <span>
            <button
              className="btn btn-primary"
              onClick={() => {
                navigate("/order");
              }}
            >
              Patron View
            </button>
          </span>
          <span>
            <button
              className="btn btn-success"
              onClick={() => {
                navigate("/staff/add-timeslot");
              }}
            >
              Add Timeslot
            </button>
          </span>
        </span>
        <span>
          <Select
            className="basic-single"
            isSearchable={true}
            defaultValue={filterOptions[0]}
            options={filterOptions}
            onChange={setSelectedOption}
          />
          {selectedOption.value === "Date" ? (
            <DatePicker
              placeholderText="Click to select a date"
              selected={startDate ? startDate : null}
              isClearable={true}
              onChange={setStartDate}
            />
          ) : (
            <div className="time-picker">
              <DatePicker
                selected={startDate}
                className="time-picker"
                onChange={(date) => setStartDate(date)}
                placeholderText="Click to select a time"
                showTimeSelect
                showTimeSelectOnly
                minTime={setHours(setMinutes(new Date(), 30), 9)}
                maxTime={setHours(setMinutes(new Date(), 60), 16)}
                isClearable={true}
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="h:mm aa"
              />
            </div>
          )}
        </span>
      </div>

      <div className="bottom-interact-container mt-1 mb-1">
        <span>
          Show entries:
          <select
            className="form-select"
            onChange={(e) => {
              setMaxPerPage(e.target.value);
            }}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </span>
      </div>

      <div className="d-flex gap-2 align-items-lg-center justify-content-end"></div>
      <section className="table-responsive">
        <table className="table caption-top">
          <thead>
            <tr>
              <th scope="col">
                <button
                  className="sort-button"
                  onClick={() => {
                    toggleSort(sortOptions.date);
                  }}
                >
                  <b>Date</b>
                  {activeSort === sortOptions.date ? (
                    !sortAscending ? (
                      <HiChevronDown />
                    ) : (
                      <HiChevronUp />
                    )
                  ) : (
                    <HiSelector />
                  )}
                </button>
              </th>
              <th scope="col">
                <button
                  className="sort-button"
                  onClick={() => {
                    toggleSort(sortOptions.startTime);
                  }}
                >
                  <b>Start Time</b>
                  {activeSort === sortOptions.startTime ? (
                    !sortAscending ? (
                      <HiChevronDown />
                    ) : (
                      <HiChevronUp />
                    )
                  ) : (
                    <HiSelector />
                  )}
                </button>
              </th>
              <th scope="col">
                <button
                  className="sort-button"
                  onClick={() => {
                    toggleSort(sortOptions.endTime);
                  }}
                >
                  <b>End Time</b>
                  {activeSort === sortOptions.endTime ? (
                    !sortAscending ? (
                      <HiChevronDown />
                    ) : (
                      <HiChevronUp />
                    )
                  ) : (
                    <HiSelector />
                  )}
                </button>
              </th>
              <th scope="col">
                <button
                  className="sort-button"
                  onClick={() => {
                    toggleSort(sortOptions.capacity);
                  }}
                >
                  <b>Capacity</b>
                  {activeSort === sortOptions.capacity ? (
                    !sortAscending ? (
                      <HiChevronDown />
                    ) : (
                      <HiChevronUp />
                    )
                  ) : (
                    <HiSelector />
                  )}
                </button>
              </th>
              <th scope="col">Display</th>
              <th scope="col">Operation</th>
            </tr>
          </thead>
          <tbody>
            {timeslots &&
              timeslots
                .filter(checkFilter)
                .sort((a, b) => sortFunc(a, b))
                .slice((page - 1) * maxPerPage, page * maxPerPage)
                .map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>{new Date(item.start_time).toLocaleDateString()}</td>
                      <td>
                        {new Date(item.start_time).toLocaleTimeString("en-US", {
                          timeStyle: "short",
                        })}
                      </td>
                      <td>
                        {new Date(item.end_time).toLocaleTimeString("en-US", {
                          timeStyle: "short",
                        })}
                      </td>
                      <td>{item.current_capacity}</td>
                      <td>{item.display ? "True" : "False"}</td>
                      <td className="d-flex gap-2">
                        <button
                          className="btn btn-warning"
                          onClick={() =>
                            navigate(`/staff/edit-timeslot/${item.id}`)
                          }
                        >
                          Edit
                        </button>
                        <button
                          className="btn btn-danger"
                          onClick={() => {
                            confirmAlert({
                              customUI: ({ onClose }) => {
                                return (
                                  <div className="ui-modal-primary">
                                    <h1>Are you sure?</h1>
                                    <p>
                                      This will remove the item{" "}
                                      <b>permanently.</b>
                                    </p>
                                    <span className="d-flex gap-2">
                                      <button
                                        className="btn btn-danger"
                                        onClick={() => {
                                          deleteTimeslot(item.id);
                                          onClose();
                                        }}
                                      >
                                        Remove
                                      </button>
                                      <button
                                        className="btn btn-secondary"
                                        onClick={onClose}
                                      >
                                        Cancel
                                      </button>
                                    </span>
                                  </div>
                                );
                              },
                            });
                          }}
                        >
                          X
                        </button>
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </table>
        {loading && timeslots.length === 0 ? <Loading /> : null}

        <div className="pagination-container">
          <div className="arrow-container">
            <PantryButton onClick={onPageDecrement}>
              <AiOutlineArrowLeft />
            </PantryButton>
            <PantryButton onClick={onPageIncrement}>
              <AiOutlineArrowRight />
            </PantryButton>
          </div>

          <PageSelector
            pageLimit={Math.ceil(timeslots.length / maxPerPage)}
            currentPage={page}
            setPage={setPage}
          />
          <a href="#top-of-page" style={{ textDecoration: "none" }}>
            <b>Jump to top</b>
          </a>
        </div>
      </section>
    </>
  );
}
