/**
 * We compare current UTC time to the date stored in the database to determine if it is recent
 * @param {*} date passed in from the database in UTC format.
 */
export default function dateParser(date) {
  const RECENT_DAY_COUNT = 2;
  return (
    Math.abs(date.split("T")[0].split("-")[2] - new Date().getUTCDate()) <=
    RECENT_DAY_COUNT
  );
}
