import "./404.css";
import { Link } from "react-router-dom";
import Image404 from "../../../resources/groceries_404.png";

export default function Http404() {
  return (
    <div className="E404-container">
      <div className="E404-image">
        <img src={Image404} alt="Error 404" />
      </div>
      <div className="E404-text">
        <h1 className="E404-title">Oops!</h1>
        <h2 className="E404-desc">
          Looks like The Pantry doesn't have this page in stock!
        </h2>
        <h3 className="E404-subtitle">(HTTP 404 - Not Found)</h3>
        <Link to="/home" className="E404-return-home-container">
          <span className="E404-return-home-animated">Return to Home</span>
        </Link>
      </div>
    </div>
  );
}
