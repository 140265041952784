import axios from "axios";
import { instance, SERVER_URL } from "../constants";
/**
 * @typedef {Object} CurrentUser
 * @property {UserData|null} data - All the user's information if logged in
 * @property {boolean} verified - False if the information comes from localStorage until verified
 */

/**
 * @typedef {Object} UserData - Basic information about a user
 * @property {string} email - User's email address
 * @property {string} username - The user's username
 * @property {string} role - The user's role
 * @property {string} accessToken - Token used to do all actions
 */

class AuthService {
  static USER_KEY = "user";
  /**
   * Should emit a "change" event to addEventListener functions each time the user information updates
   */
  updates = new EventTarget();

  /**
   * Call whenever the currentUser object is changed to notify React components listening in to the user
   * @param {CurrentUser} [updatedCurrentUser] Important: Not strongly type validated. When provided, the currentUser will be replaced before changes are emitted
   */
  emitUpdate(updatedCurrentUser) {
    if (
      typeof updatedCurrentUser === "object" &&
      this.currentUser.data !== undefined
    ) {
      this.currentUser = updatedCurrentUser;
      localStorage.setItem(
        AuthService.USER_KEY,
        JSON.stringify(this.currentUser.data)
      );
    }
    this.updates.dispatchEvent(
      new CustomEvent("change", { detail: this.currentUser })
    );
  }

  /** @type {CurrentUser} */
  currentUser = {
    data: null,
    verified: false,
  };

  constructor() {
    const storedUser = localStorage.getItem(AuthService.USER_KEY);
    try {
      this.currentUser.data = JSON.parse(storedUser);
    } catch (err) {
      console.warn("Unable to parse stored user JSON");
      console.error(err);
    }

    // Verify validity and emit an update
    if (this.currentUser.data) {
      this.getUserInfo().catch((err) => {
        console.warn("Unable to verify user data");
        console.error(err);
        this.logout();
      });
    }
  }

  /**
   * Get current user's information as defined in database
   * @returns {Promise<UserData>}
   */
  getUserInfo() {
    return instance
      .post("/api/get-user/", {
        token: this.currentUser.data.accessToken,
      })
      .then((response) => {
        if (response.status !== 200)
          throw new RangeError(
            `Server Responded with a ${response.status} while getting user info`
          );
        this.emitUpdate({
          data: {
            ...this.currentUser.data,
            ...response.data,
          },
          verified: true,
        });
        return response.data;
      });
  }

  /**
   * Logout the current user by deleting from localStorage and emitting change
   */
  logout() {
    localStorage.removeItem(AuthService.USER_KEY);
    this.emitUpdate({
      data: null,
      verified: false,
    });
  }

  /**
   * Will not be used in production (Google only)
   * Login a user given their username and password
   * @param {string} username String of user's username
   * @param {string} password String of user's password
   * @returns {Promise<*>}
   * @deprecated
   */
  login(username, password) {
    return axios
      .post(
        "/api/api-token-auth/",
        {
          username,
          password,
        },
        { baseURL: SERVER_URL }
      )
      .then((response) => {
        if (response.status !== 200)
          throw new RangeError(
            `Server Responded to Login with ${response.status}`
          );
        if (!response.data.accessToken)
          throw new TypeError("No Access Token Found In Data");

        this.emitUpdate({
          data: response.data,
          verified: true,
        });
      });
  }

  /**
   * @description Create a new user
   * @arg username String of username
   * @arg email String of email
   * @arg password String of password
   * @returns None
   * @deprecated
   */
  register(username, email, password) {
    return instance
      .post("/api/register/", {
        username,
        email,
        password,
      })
      .catch(console.error);
  }
}

export default new AuthService();
