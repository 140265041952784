import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import AuthService from "../../services/auth.service";
import { SERVER_URL } from "../../constants";

async function submitCode(code, searchParams) {
  const codeParams = { code: code };
  const response = await axios.post("/api/google/connect/", codeParams, {
    baseURL: SERVER_URL,
    headers: { "X-CSRFToken": Cookies.get("csrftoken") },
  });

  const tokenParams = { token: response.data.key };

  const res = await axios.post("/api/get-account/", tokenParams, {
    baseURL: SERVER_URL,
    headers: { "X-CSRFToken": Cookies.get("csrftoken") },
  });

  /** @type {UserData} */
  const userObj = {
    accessToken: res.data.accessToken,
    username: res.data.username,
    email: res.data.email,
    role: res.data.role,
  };

  AuthService.emitUpdate({
    data: userObj,
    verified: true,
  });

  // Redirect them back to their original location if it exists
  const urlParams = new URLSearchParams(searchParams);
  window.location.href = urlParams.get("continue") || "/";
}

export default function GoogleLogin() {
  const params = useParams();
  const route = useLocation();
  const code = params["*"];
  const [failed, setFailed] = useState(false);

  useEffect(() => {
    submitCode(code, route.search).catch((err) => setFailed(err));
  }, [code, route.search]);

  return (
    <div
      className="d-flex flex-column justify-content-center"
      style={{ flexGrow: 1 }}
    >
      {failed ? (
        <>
          <h1 className="center">Oops! We couldn't log you in!</h1>
          <p className="center h6">
            Something went wrong. Please try again later
          </p>
          {failed.message && (
            <p className="center text-muted">
              Technical details: {failed.message}
            </p>
          )}
        </>
      ) : (
        <>
          <h1 className="center">Logging you in...</h1>
          <p className="center">You should be redirected shortly!</p>
        </>
      )}
    </div>
  );
}
