import React from "react";
import PantryLogo from "../../resources/pantry-icon-color.png";
import AppNav from "./AppNav";
import { Link } from "react-router-dom";
import "./AppFrames.css";

export default function AppDefaultFrame({ centerLogo = false, children }) {
  return (
    <>
      <AppNav showNavLogo={!centerLogo} />
      <AppFrame hideLogo={!centerLogo}>{children}</AppFrame>
      <FeedbackFooter />
    </>
  );
}

function AppFrame({ children, hideLogo = false }) {
  return (
    <div
      className={`white-app-frame-container d-flex flex-column align-items-center position-relative mx-auto ${
        !hideLogo ? "mt-md-5 pt-5" : "mt-0"
      } mb-2 mt-xxl-0`}
    >
      <Link
        to="/"
        className={`position-absolute logo-container ${
          !hideLogo ? "show-logo" : "d-none"
        }`}
      >
        <img className="shadow" src={PantryLogo} alt="Pantry Logo" />
      </Link>

      <main
        className={`app-frame-main container-fluid ${
          !hideLogo ? "py-4 pt-md-2" : "py-5"
        }`}
      >
        {children}
      </main>
    </div>
  );
}

function FeedbackFooter() {
  return (
    <footer className="feedback-div">
      <p>
        To report a bug or give feedback, please click{" "}
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSc-GPI3VkbMqy6nGTJu4GWpO8cOy9SdqaAqOBEtnpsmjnVBVA/viewform">
          HERE
        </a>
        .
      </p>
    </footer>
  );
}
