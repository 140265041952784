import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineInfoCircle,
  AiOutlineWarning,
} from "react-icons/ai";
import { HiChevronUp, HiChevronDown, HiSelector } from "react-icons/hi";
import dateParser from "../../../../helpers/date-parser";
import { confirmAlert } from "react-confirm-alert";
import { MdOutlineFiberNew } from "react-icons/md";
import { EXTENDED_ALLERGENS, SHORT_CATEGORIES } from "../../../../constants";
import StaffService from "../../../../services/staff.service";
import PublicApiService from "../../../../services/public-api.service";
import PantryButton from "../../../parts/PantryButton";
import Loading from "../../../parts/Loading";
import "react-confirm-alert/src/react-confirm-alert.css";
import "./inventory.css";
import PageSelector from "../../../parts/PageSelector";
import { useItems } from "../../../../helpers/publicAPIHooks";

/* Table filter options */
const sortOptions = {
  name: "name",
  amount: "amount",
  onHold: "onHold",
  threshold: "threshold",
  limit: "limit",
  date_added: "date_added",
  canOrder: "canOrder",
};

/**
 * @description This component renders the inventory page for staff only.
 * Patrons should not have access to this page whatsoever.
 *
 * Fetches data from the specified api endpoint.
 * Staff is able to preform any CRUD Operation on any item in the inventory.
 * @returns JSX.Element
 */
export default function Inventory() {
  const { items, itemsLoading } = useItems();
  const [searchItem, setSearchItem] = useState("");
  const [orderQuantity, setOrderQuantity] = useState(0);
  const [loading, isLoading] = useState(false);

  // Table filter hooks
  const [sortAscending, setSortAscending] = useState(false);
  const [activeSort, setActiveSort] = useState(sortOptions.date_added);

  // Pagination hooks
  const [page, setPage] = useState(1);
  const [maxPerPage, setMaxPerPage] = useState(10);

  const navigate = useNavigate();

  // Build items on hold
  useEffect(() => {
    const init = async () => {
      isLoading(true);

      const [orderArr, timeArr] = await Promise.all([
        StaffService.fetchOrders(),
        PublicApiService.getTimeslots(),
      ]);

      // Create timeslot map to map ts id to a ts
      const timeslotMap = new Map(timeArr.map((ts) => [ts.id, ts]));

      // Reduced 4 array passes to 1

      let order_quantity = {};

      // go through each order
      orderArr.forEach((order) => {
        // Convert timeslot id to timeslot data
        order.timeslot = timeslotMap.get(order.time_slot);

        // Filter placed orders
        if (order.status === "PL") {
          // Build JSON object
          order.order_products.forEach((item) => {
            // count of amount item is ordered
            order_quantity[item.item] =
              (order_quantity[item.item] || 0) + item.quantity;
          });
        }
      });

      // Set state data
      setOrderQuantity(order_quantity);
      isLoading(false);
    };
    init();
  }, []);

  // here
  const [updatedItems, setUpdatedItems] = useState([]);
  useEffect(() => {
    if (!itemsLoading) {
      const updatedItemsWithCanOrder = items.map((item) => ({
        ...item,
        can_order: item.can_order !== undefined ? item.can_order : false,
      }));

      // update status
      setUpdatedItems(updatedItemsWithCanOrder);
    }
  }, [items, itemsLoading]);

  const toggleSort = (option) => {
    if (activeSort === option) {
      setSortAscending(!sortAscending);
      return;
    }
    setActiveSort(option);
    setSortAscending(true);
  };

  const deleteItemById = async (itemId) => {
    StaffService.deleteItem(itemId)
      .then((response) => {
        if (response.status === 204) {
          // Success
          window.location.pathname = "/staff";
        } else if (response.status === 400) {
          // Client error
          alert(response.data);
        } else if (response.status === 500) {
          // Server error
          alert(response.data);
        }
      })
      .catch((err) => {
        console.err(err);
      });
  };

  function onPageDecrement() {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  }

  function onPageIncrement() {
    if (page < Math.ceil(items.length / maxPerPage)) {
      setPage((prevPage) => prevPage + 1);
    }
  }

  /* Sorting for table's filter arrows */
  function sortFunc(a, b) {
    let valueA, valueB;
    switch (activeSort) {
      case sortOptions.amount: // Sort by amount
        valueA = a.amount;
        valueB = b.amount;
        return sortAscending ? valueA - valueB : valueB - valueA;

      case sortOptions.limit: // Sort by limit
        valueA = a.limit;
        valueB = b.limit;
        return sortAscending ? valueA - valueB : valueB - valueA;

      case sortOptions.threshold: // Sort by threshold
        valueA = a.threshold;
        valueB = b.threshold;
        return sortAscending ? valueA - valueB : valueB - valueA;

      case sortOptions.name: // Sort by name
        valueA = a.name.toLowerCase(); // for case-insensitive sorting
        valueB = b.name.toLowerCase();
        return sortAscending
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);

      case sortOptions.canOrder: // Sort by can order
        // boolean
        valueA = !!a.can_order;
        valueB = !!b.can_order;
        return sortAscending ? valueB - valueA : valueA - valueB;

      case sortOptions.onHold: // sort for on hold
        valueA = a.onHold;
        valueB = b.onHold;
        return sortAscending ? valueA - valueB : valueB - valueA;

      default: // Set default case as sorting by date
        valueA = new Date(a.date_added);
        valueB = new Date(b.date_added);
        return sortAscending ? valueA - valueB : valueB - valueA;
    }
  }

  const displayedOrderedInventory = items
    .filter((item) => {
      let itemName = item.name.toLowerCase();
      if (!searchItem) {
        // Empty input field
        return true;
      }
      return itemName.includes(searchItem.toLowerCase());
    })
    .sort((a, b) => sortFunc(a, b));

  return (
    <div className="mx-0 mx-sm-2 mx-lg-3 mx-xl-5">
      <h1 className="text-center" id="top-of-page">
        Inventory
      </h1>
      <div className="d-flex justify-content-between gap-2">
        <span className="d-flex gap-2">
          <button
            className="btn btn-success"
            onClick={() => {
              navigate("/staff/add-item");
            }}
          >
            Add Item
          </button>
        </span>
        <span>
          <div className="input-group">
            <input
              className="form-control"
              id="search-input"
              type="text"
              placeholder="Search by Name"
              onChange={(e) => {
                setPage(1);
                setSearchItem(e.target.value);
              }}
              autoComplete="off"
            />
            <button
              className="btn btn-outline-danger"
              type="button"
              onClick={() => {
                // Clear input field
                document.getElementById("search-input").value = "";
                setSearchItem("");
              }}
            >
              Clear
            </button>
          </div>
        </span>
      </div>
      <div className="bottom-interact-container mt-1 mb-1">
        <span>
          Show entries:
          <select
            className="form-select"
            onChange={(e) => {
              setMaxPerPage(e.target.value);
              setPage(1);
            }}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </span>
      </div>
      <section className="table-responsive">
        <table className="table caption-top">
          <thead>
            <tr>
              {/* if clicked then only that Category is active
               and is opposite of whatever it is last time
               e.g. ascending -> button -> descending
               */}
              <th scope="col">
                <button
                  className="sort-button"
                  onClick={() => {
                    toggleSort(sortOptions.name);
                  }}
                >
                  <b>Name</b>
                  {activeSort === sortOptions.name ? (
                    !sortAscending ? (
                      <HiChevronDown />
                    ) : (
                      <HiChevronUp />
                    )
                  ) : (
                    <HiSelector />
                  )}
                </button>
              </th>
              <th scope="col">
                <button
                  className="sort-button"
                  onClick={() => {
                    toggleSort(sortOptions.amount);
                  }}
                >
                  <b>Amount</b>
                  {activeSort === sortOptions.amount ? (
                    !sortAscending ? (
                      <HiChevronDown />
                    ) : (
                      <HiChevronUp />
                    )
                  ) : (
                    <HiSelector />
                  )}
                </button>
              </th>

              <th scope="col">
                <button
                  className="sort-button"
                  onClick={() => {
                    toggleSort(sortOptions.onHold);
                  }}
                >
                  <b>On Hold</b>
                  {activeSort === sortOptions.onHold ? (
                    !sortAscending ? (
                      <HiChevronDown />
                    ) : (
                      <HiChevronUp />
                    )
                  ) : (
                    <HiSelector />
                  )}
                </button>
              </th>

              <th scope="col">
                <button
                  className="sort-button"
                  onClick={() => {
                    toggleSort(sortOptions.limit);
                  }}
                >
                  <b>Limit</b>
                  {activeSort === sortOptions.limit ? (
                    !sortAscending ? (
                      <HiChevronDown />
                    ) : (
                      <HiChevronUp />
                    )
                  ) : (
                    <HiSelector />
                  )}
                </button>
              </th>

              <th scope="col">
                <button
                  className="sort-button"
                  onClick={() => {
                    toggleSort(sortOptions.threshold);
                  }}
                >
                  <b>Threshold</b>
                  {activeSort === sortOptions.threshold ? (
                    !sortAscending ? (
                      <HiChevronDown />
                    ) : (
                      <HiChevronUp />
                    )
                  ) : (
                    <HiSelector />
                  )}
                </button>
              </th>

              {/* here */}
              <th scope="col">
                <button
                  className="sort-button"
                  onClick={() => {
                    toggleSort(sortOptions.canOrder);
                  }}
                >
                  <b>Can Order</b>
                  {activeSort === sortOptions.canOrder ? (
                    !sortAscending ? (
                      <HiChevronDown />
                    ) : (
                      <HiChevronUp />
                    )
                  ) : (
                    <HiSelector />
                  )}
                </button>
              </th>

              <th scope="col">
                <div className="category-box">
                  <span
                    className="info"
                    title="VG = Vegetarian, V+ = Vegan, KO = Kosher, HA = Halal, LO = Local, UN = Unknown"
                  >
                    <AiOutlineInfoCircle />
                  </span>
                  <p className="p-reset">Category</p>
                </div>
              </th>

              <th scope="col">Allergen</th>

              <th scope="col">
                <div className="category-box">Item Description</div>
              </th>

              {/* temp: not using */}
              {/* <th scope="col">
                <button
                  className="sort-button"
                  onClick={() => {
                    toggleSort(sortOptions.date);
                  }}
                >
                  <b>Date</b>
                  {activeSort === sortOptions.date ? (
                    !sortAscending ? (
                      <HiChevronDown />
                    ) : (
                      <HiChevronUp />
                    )
                  ) : (
                    <HiSelector />
                  )}
                </button>
              </th> */}

              <th scope="col">Operation</th>
            </tr>
          </thead>
          <tbody>
            {displayedOrderedInventory
              .slice((page - 1) * maxPerPage, page * maxPerPage)
              .map((item) => (
                <InventoryItem
                  key={item.id}
                  item={item}
                  deleteItemById={deleteItemById}
                  order_quantity={orderQuantity}
                />
              ))}
          </tbody>
        </table>
        {(itemsLoading || loading) && items.length === 0 ? <Loading /> : null}
      </section>

      <div className="pagination-container">
        <div className="arrow-container">
          <PantryButton onClick={onPageDecrement}>
            <AiOutlineArrowLeft />
          </PantryButton>
          <PantryButton onClick={onPageIncrement}>
            <AiOutlineArrowRight />
          </PantryButton>
        </div>

        <PageSelector
          pageLimit={Math.ceil(items.length / maxPerPage)}
          currentPage={page}
          setPage={setPage}
        />
        <a href="#top-of-page" style={{ textDecoration: "none" }}>
          <b>Jump to top</b>
        </a>
      </div>
    </div>
  );
}

function InventoryItem({ item, deleteItemById, order_quantity }) {
  // temp: not using
  // const timestamp = new Date(item.timestamp).toLocaleDateString();

  // console.log(order_quantity[item.id]);
  const recentlyAdded = dateParser(item.date_added);
  let NameIcon;
  if (recentlyAdded) NameIcon = MdOutlineFiberNew;
  if (!item.can_order) NameIcon = AiOutlineWarning;

  // const storageType = STORAGE_TYPES.find((type) => type.value === item.storage_type)?.handle;
  const allergenListStr = item.allergen
    .map(
      (agn) =>
        EXTENDED_ALLERGENS.find((allergen) => allergen.value === agn)?.handle
    )
    .join(", ");
  const canOrderStr = item.can_order ? "True" : "False";

  const trimString = (text, maxLength) => {
    if (text.length > maxLength) {
      return `${text.substring(0, maxLength - 3)}...`;
    }
    return text;
  };

  return (
    <tr className="align-middle">
      {/* {console.log('hi', item)} */}
      <td
        className={`${recentlyAdded ? "text-success" : ""} ${
          !item.can_order ? "text-danger" : ""
        }`}
      >
        {NameIcon && <NameIcon />} {item.name}
      </td>
      <td>{item.amount}</td>
      <td>{order_quantity[item.id] || 0}</td>
      <td>{item.limit}</td>
      <td>{item.threshold}</td>
      <td>{canOrderStr}</td>
      <td>
        {item.categories
          .map(
            (ctg) =>
              SHORT_CATEGORIES.find((category) => category.value === ctg)
                ?.handle
          )
          .join(", ")}
      </td>
      <td>{allergenListStr}</td>
      <td>{trimString(item.item_description, 25)}</td>
      

      {/* temp: not using <td>{timestamp}</td> */}

      <td className="text-nowrap">
        <Link className="btn btn-warning" to={`/staff/edit-item/${item.id}`}>
          Edit
        </Link>
        <button
          className="ms-2 btn btn-danger"
          onClick={() => {
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div className="ui-modal-primary">
                    <h1>Are you sure?</h1>
                    <p>
                      This will remove the item <b>permanently.</b>
                    </p>
                    <span className="d-flex gap-2">
                      <button
                        className="btn btn-danger"
                        onClick={() => {
                          deleteItemById(item.id);
                          onClose();
                        }}
                      >
                        Remove
                      </button>
                      <button className="btn btn-secondary" onClick={onClose}>
                        Cancel
                      </button>
                    </span>
                  </div>
                );
              },
            });
          }}
        >
          X
        </button>
      </td>
    </tr>
  );
}
