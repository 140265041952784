import { TimeslotForm } from "../../sections/timeslot-form";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PublicApiService from "../../../services/public-api.service";

/**
 * Sends a PATCH request to update item in the server
 * @returns JSX.Element
 */
export default function EditTimeslot() {
  const [date, setDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [capacity, setCapacity] = useState(5);
  const [display, setDisplay] = useState(true);

  const params = useParams(); // Data passed in from url

  useEffect(() => {
    //Fetch timeslot from id
    PublicApiService.getSingleTimeslot(params.id).then((item) => {
      //Load into states
      setDate(new Date(item.start_time));
      setStartTime(new Date(item.start_time));
      setEndTime(new Date(item.end_time));
      setCapacity(item.current_capacity);
      setDisplay(Boolean(item.display));
    });
  }, [params]);

  return (
    <TimeslotForm // Pass into the form
      date={date}
      setDate={setDate}
      startTime={startTime}
      setStartTime={setStartTime}
      endTime={endTime}
      setEndTime={setEndTime}
      capacity={capacity}
      setCapacity={setCapacity}
      display={display}
      setDisplay={setDisplay}
      timeslotID={params.id}
    />
  );
}
