import { useCallback, useMemo } from "react";
import { useCart } from "react-use-cart";
import useAuth from "../../../helpers/useAuth";

/**
 * Hook for obtaining functions for incrementing and decrementing items in the cart,
 * Ignore the useMemo() and useCallback(), they're only there for minimal optimization
 * @return {handleIncrement: function, handleDecrement: function} Each function takes in an item and will increment/decrement them in the cart
 */
export default function useIncrementDecrement() {
  const { totalItems, getItem, addItem, updateItemQuantity } = useCart();
  const { data: user } = useAuth();
  const isStaff = user ? user.role === "Staff" : false;

  const handleIncrement = useCallback(
    (item) => {
      const tempItem = getItem(item.id); // Needed to grab cart quantity
      if (
        (!tempItem && isStaff) ||
        (isStaff && tempItem.quantity < item.amount)
      ) {
        addItem({ ...item, price: 1 });
      } else if (
        (!tempItem && item.can_order) || //If item is not in cart, add it
        (item.can_order && totalItems < 40 && tempItem.quantity < item.limit) //If item can be ordered, total not reached, and is below the limit of item
      ) {
        addItem({ ...item, price: 1 });
      } else {
        // window.alert('Item cannot be ordered at the moment!');
        // setAlert(`Reached limit for ${item.name}!`);
        // setIsAlertOpen(true);
      }
    },
    [totalItems, addItem, getItem, isStaff]
  );

  const handleDecrement = useCallback(
    (item) => {
      const tempItem = getItem(item.id); //Needed to grab cart quantity
      updateItemQuantity(item.id, Math.max(0, tempItem.quantity - 1));
    },
    [getItem, updateItemQuantity]
  );

  return useMemo(
    () => ({
      handleIncrement,
      handleDecrement,
    }),
    [handleIncrement, handleDecrement]
  );
}
