import { useEffect, useRef, useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { IconContext } from "react-icons";
import { HiOutlineChevronUp } from "react-icons/hi";
import { Tooltip } from "bootstrap";
import TreeNut from "../../resources/system-icons/Tree_Nuts.png";
import Peanuts from "../../resources/system-icons/Peanuts.png";
import Milk from "../../resources/system-icons/Milk.png";
import Soy from "../../resources/system-icons/Soy.png";
import Crustacean from "../../resources/system-icons/Crustacean.png";
import Fish from "../../resources/system-icons/Fish.png";
import Egg from "../../resources/system-icons/Egg.png";
import Wheat from "../../resources/system-icons/Wheat.png";
import No from "../../resources/system-icons/No.png";
import Sesame from "../../resources/system-icons/Sesame.png";
import { ALLERGEN_NAMES } from "../../constants";
import "./product-modal.css";

const ALLERGEN_IMAGES = {
  NO: No,
  PE: Peanuts,
  CH: TreeNut,
  TN: TreeNut,
  ML: Milk,
  SO: Soy,
  EG: Egg,
  SH: Crustacean,
  FS: Fish,
  WH: Wheat,
  UN: No,
  SE: Sesame,
};

/**

A React component for displaying an item modal in the order item page.
@param {Object} props - The component props.
@param {Object} props.item - The item object to display in the modal.
@returns {JSX.Element} The item modal component.
*/
export default function ItemModal({ item }) {
  const [itemModalOpen, setModalOpen] = useState(false);
  let imgURL = `${process.env.PUBLIC_URL || ""}/static/none.jpg`;
  if (item.image) {
    imgURL = !process.env.REACT_APP_S3_ENABLED
      ? `/media/${item.image}`
      : item.image;
  }

  return (
    <div>
      <div className="item-div">
        <div className="d-block position-relative text-nowrap product-image-section">
          <IconContext.Provider
            value={{
              size: "1.8em",
              color: "mediumblue",
              className:
                "info-icon position-absolute top-0 left-0 bg-white rounded-circle position-md-static",
            }}
          >
            {itemModalOpen ? (
              <HiOutlineChevronUp
                onClick={() => setModalOpen(!itemModalOpen)}
              />
            ) : (
              <AiOutlineInfoCircle
                onClick={() => setModalOpen(!itemModalOpen)}
              />
            )}
          </IconContext.Provider>

          <img
            className="cursor-pointer product-image"
            src={imgURL}
            alt="Product Image"
            onClick={() => setModalOpen(!itemModalOpen)}
          />
          <span className="d-inline-block d-md-none px-2">{item.name}</span>
        </div>

        {itemModalOpen && (
          <div className="item-modal">
            <p className="bold">Limit: {item.limit}</p>
            <div className="allergen">
              <p className="bold">Allergens:</p>
              <div className="all-allergen-imgs">
                {item &&
                  item.allergen.map((al) => {
                    return (
                      <AllergenIcon
                        key={al}
                        className="allergen-icon"
                        data-toggle="tooltip"
                        data-placement="top"
                        title={ALLERGEN_NAMES[al]}
                        src={ALLERGEN_IMAGES[al]}
                        alt={al}
                      />
                    );
                  })}
              </div>
            </div>
            <div className="description">
              <p className="bold">Description:</p>
              {item.item_description != "" ? item.item_description : "None"}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function AllergenIcon(props) {
  const { alt } = props;
  const allergenRef = useRef();

  useEffect(() => {
    if (!allergenRef.current) return;
    new Tooltip(allergenRef.current);
  }, []);

  return <img {...props} alt={alt} ref={allergenRef} />;
}
