import { instance } from "../constants";

/**
 * Class containing the different methods available for staff members.
 * All methods should fail if not authorized
 * @class {StaffService}
 */
class StaffService {
  // Item services
  /**
   * @description Adds unique item to inventory
   * @POST request
   * @args Order in object format
   * @returns HttpResponse object
   */
  addItem(itemInformation) {
    return instance
      .post("/api/add-item/", itemInformation)
      .catch((err) => err.response);
  }

  /**
   * @description Edit item currently in inventory
   * @PATCH request
   * @args (Item, item ID)
   * @returns HttpResponse object
   */
  editItem(itemInformation, itemId) {
    return instance
      .patch(`/api/edit-item/${itemId}/`, itemInformation)
      .catch((err) => err.response);
  }

  /**
   * @description Removes item from inventory
   * @DELETE request
   * @args Item ID to delete
   * @returns None
   */
  deleteItem(itemId) {
    return instance.delete(`/api/items/${itemId}/`).catch(console.error);
  }

  /**
   * @description Fetches specific item from inventory based on id
   * @GET request
   * @args Item ID to fetch
   * @returns Item object
   */
  fetchItem(itemId) {
    return instance
      .get(`/api/items/${itemId}/`)
      .then((response) => response.data)
      .catch(console.error);
  }

  // Timeslot services
  /**
   * @description Add timeslot to inventory
   * @POST request
   * @args timeslot in object format
   * @returns HttpResponse object
   */
  addTimeslot(timeslotInformation) {
    return instance
      .post("/api/add-timeslot/", { timeslotInformation })
      .then((response) => response)
      .catch((err) => err.response);
  }

  /**
   * @description Edit timeslot currently in inventory
   * @PATCH request
   * @args (Timeslot, Timeslot ID)
   * @returns HttpResponse object
   */
  editTimeslot(timeslotInformation, timeslotID) {
    return instance
      .patch(`/api/edit-timeslot/${timeslotID}/`, { timeslotInformation })
      .then((response) => response)
      .catch((err) => err.response);
  }

  /**
   * @description Removes timeslot from inventory
   * @DELETE request
   * @args Timeslot ID to delete
   * @returns {Promise}
   */
  deleteTimeslot(timeslotID) {
    return instance
      .delete(`/api/timeslots/${timeslotID}/`)
      .catch(console.error);
  }

  /**
   * @description Fetch single order by id
   * @GET request
   * @args orderId
   * @returns Object containing order information
   */
  fetchOrder(orderId) {
    return instance
      .get(`/api/order/${orderId}/`)
      .then((response) => response.data)
      .catch(console.error);
  }

  /**
   * @description Fetches all orders from any status
   * @GET request
   * @returns array of orders in JSON format
   */
  fetchOrders() {
    return instance
      .get("/api/order/")
      .then((response) => response.data)
      .catch(console.error);
  }

  /**
   * @description Fetches all orders for current user
   * @GET request
   * @returns array of orders in JSON format
   */
  getUserOrders(email) {
    return instance
      .get(`/api/order/?email=${email}`)
      .then((response) => response.data)
      .catch(console.error);
  }

  /**
   * @description Removes selected order from staff orders page
   * @DELETE request
   * @args Target order to delete (ID)
   * @returns Axios response
   */
  deleteOrder(targetOrder) {
    return instance.delete(`/api/order/${targetOrder}/`).catch(console.error);
  }

  /**
   * @description Edit specific order
   * @PATCH request
   * @args (orderInformation, orderId)
   * @returns None
   */
  editOrder(orderInformation, orderId) {
    return instance
      .patch(`/api/edit-order/${orderId}/`, orderInformation)
      .catch(console.error);
  }

  /**
   * @description Edit specific order for volunteers
   * @PATCH request
   * @args (orderInformation, orderId)
   * @returns None
   */
  volunteerEditOrder(orderInformation, orderId) {
    return instance
      .patch(`/api/volunteer-edit-order/${orderId}/`, orderInformation)
      .catch(console.error);
  }

  /**
   * @description Change orders status only
   * @PATCH request
   * @args (orderInformation, orderId)
   * @returns None
   */
  async editOrderStatus(orderInformation, orderId) {
    return instance.patch(
      `/api/edit-order-status/${orderId}/`,
      orderInformation
    );
  }

  // Google Sheets Services

  /**
   * @description Create Spreadsheet for requested date
   * @PATCH POST
   * @args (spreadsheetName)
   * @returns None
   */
  createSpreadsheet(date) {
    return instance
      .post("/api/create-spreadsheet/", { date })
      .catch(console.error);
  }

  /**
   * @description Import Spreadsheet to backend for requested date
   * @PATCH POST
   * @args (spreadsheetName)
   * @returns None
   */
  importSpreadsheet(date) {
    return instance
      .post("/api/import-spreadsheet/", { date })
      .catch(console.error);
  }
}

export default new StaffService();
