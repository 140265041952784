import { instance } from "../constants";

/**
 * Handles all API calls that don't require any credentials
 * Caches results for instant access until forced to re-fetch
 * All methods currently fail silently
 */
class PublicAPIService {
  static ENABLE_CACHE = true;
  itemsCache = {
    lastRefresh: 0,
    data: [],
    expires: 60 * 60, // Hourly Cache
  };
  timeslotCache = {
    lastRefresh: 0,
    data: [],
    expires: 60 * 60, // Hourly Cache
  };
  validTimeslotCache = {
    lastRefresh: 0,
    data: [],
    expires: 60 * 60, // Cache for one hour
  };

  /**
   * Fetches all items and caches. Be advised: Skip cache when the current item count and most up-to-date result matters
   * @param {boolean} [skipCache] When set to true, cache is skipped and timeslots are all refreshed
   * @async
   * @return {Promise<*[]>} Array of items
   */
  async getItems(skipCache) {
    if (
      !skipCache &&
      PublicAPIService.ENABLE_CACHE &&
      this.itemsCache.lastRefresh + this.itemsCache.expires * 1000 > Date.now()
    ) {
      return this.itemsCache.data;
    }

    const res = await instance.get("/api/items/");
    if (res.status !== 200) {
      // Silent failure
      console.warn(
        `Failed to Fetch Items: Server Responded with a ${res.status}`
      );
      return [];
    }
    this.itemsCache.data = res.data;
    this.itemsCache.lastRefresh = Date.now();
    return this.itemsCache.data;
  }

  /**
   * Fetches all timeslots for viewing. Be advised: Skip cache when the current capacity and most up-to-date result matters
   * @param {boolean} [skipCache] When set to true, cache is skipped and timeslots are all refreshed
   * @return {Promise<*[]>} Array of timeslots
   */
  async getTimeslots(skipCache) {
    if (
      !skipCache &&
      PublicAPIService.ENABLE_CACHE &&
      this.timeslotCache.lastRefresh + this.timeslotCache.expires * 1000 >
        Date.now()
    ) {
      return this.timeslotCache.data;
    }

    const res = await instance.get("/api/timeslots/");
    if (res.status !== 200) {
      // Silent failure
      console.warn(
        `Failed to Fetch Timeslots: Server Responded with a ${res.status}`
      );
      return [];
    }
    this.timeslotCache.data = res.data;
    this.timeslotCache.lastRefresh = Date.now();
    return this.timeslotCache.data;
  }

  /**
   * Fetches valid timeslots for the upcoming week. Be advised: Skip cache when the most up-to-date result matters
   * @param {boolean} [skipCache] When set to true, cache is skipped and valid timeslots are all refreshed
   * @return {Promise<*[]>} Array of valid timeslots
   */
  async getValidTimeslots(skipCache) {
    if (
      !skipCache &&
      PublicAPIService.ENABLE_CACHE &&
      this.validTimeslotCache.lastRefresh +
        this.validTimeslotCache.expires * 1000 >
        Date.now()
    ) {
      return this.validTimeslotCache.data;
    }

    const res = await instance.get("/api/valid-timeslots/");
    if (res.status !== 200) {
      // Silent failure
      console.warn(
        `Failed to Fetch Valid Timeslots: Server Responded with a ${res.status}`
      );
      return [];
    }
    this.validTimeslotCache.data = res.data;
    this.validTimeslotCache.lastRefresh = Date.now();
    return this.validTimeslotCache.data;
  }

  ongoingSingleTimeslotRequests = {};
  /**
   * Fetches a single timeslot. Searches from the cache first and sends a request if it doesn't exist.
   * Note: This will still search through an expired timeslots cache. Forcibly skip cache if this is unacceptable
   * @param {number|string} timeslotId
   * @param {boolean} [skipCache] When set to true, cache is skipped and timeslots are all refreshed
   * @return {Promise<Object>} The timeslot object
   */
  async getSingleTimeslot(timeslotId, skipCache) {
    if (
      !skipCache &&
      PublicAPIService.ENABLE_CACHE &&
      this.timeslotCache.lastRefresh
    ) {
      const locatedSlot = this.timeslotCache.data.find(
        (timeslot) => timeslot.id === Number(timeslotId)
      );
      // Check if slot exists in cache
      if (locatedSlot) {
        // Return cached timeslot if not expired individually or within the full timeslot cache
        if (
          this.timeslotCache.lastRefresh + this.timeslotCache.expires >
          Date.now()
        ) {
          return locatedSlot;
        } else if (
          locatedSlot.lastRefresh &&
          locatedSlot.lastRefresh + this.timeslotCache.expires > Date.now()
        ) {
          return locatedSlot;
        }
      }
    }

    // Only send out a request for a specific timeslot if one is not already sent
    // Otherwise, reuse the same request's response
    const ongoingRequest = this.ongoingSingleTimeslotRequests[timeslotId];
    if (!ongoingRequest) {
      // Tracks all ongoing-network requests so that multiple calls for the same id can reuse the same request
      const timeslotRequest = instance.get(`/api/timeslots/${timeslotId}/`);

      const processTimeslotRequest = (response) => {
        const newTimeslotData = response.data;

        // Add to cache if it exists while preserving order if possible
        newTimeslotData.lastRefresh = Date.now();
        if (this.timeslotCache.data) {
          const replaceIndex = this.timeslotCache.data.findIndex(
            (slot) => slot.id === Number(timeslotId)
          );
          if (replaceIndex !== -1) {
            this.timeslotCache.data.splice(replaceIndex, 1, newTimeslotData);
          } else {
            this.timeslotCache.data.push(newTimeslotData);
          }
        }

        return newTimeslotData;
      };

      this.ongoingSingleTimeslotRequests[timeslotId] = timeslotRequest.then(
        processTimeslotRequest
      );
    }

    const timeslotData = await this.ongoingSingleTimeslotRequests[timeslotId];
    // Remove from ongoing requests now that it has been cached
    delete this.ongoingSingleTimeslotRequests[timeslotId];
    return timeslotData;
  }
}

export default new PublicAPIService();
