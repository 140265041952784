import React from "react";
import { Link, useLocation } from "react-router-dom";
import useAuth from "../../../helpers/useAuth";
import ImageAuthWall from "../../../resources/authwall.png";
import { AUTH_URL } from "../../../constants";
import "./authwall.css";

/**
 * Blocks off pages that certain users shouldn't be able to access<br/>
 * @param {string|JSX.Element} children - React components to show if auth wall is cleared
 * @param {string[]} [allowRoles=["Staff"]] - Roles allowed past the auth wall
 * @return {JSX.Element|*}
 * @constructor
 */
export default function AuthWall({ children, allowRoles = ["Staff"] }) {
  const { data: user } = useAuth();
  const route = useLocation();
  const currentRoute = route.pathname || "/";

  if (
    user &&
    allowRoles.some((allowedRole) => user.role.includes(allowedRole))
  ) {
    return children;
  }

  return (
    <div className="authwall-container">
      <div className="authwall-image">
        <img src={ImageAuthWall} alt="Login to Continue" />
      </div>
      <div className="authwall-text">
        <h1 className="authwall-title">Are you logged in? </h1>
        <h2 className="authwall-desc">
          You must be logged in as a {allowRoles.join("/").toLowerCase()} member
          to see this page
        </h2>
        <h3 className="authwall-subtitle">Make sure you're logged in!</h3>
        <a
          href={`${AUTH_URL}&state=${encodeURIComponent(
            `continue=${currentRoute}`
          )}`}
          className="authwall-login-continue-container"
        >
          <span className="authwall-login-continue-animated">
            Login to Continue
          </span>
        </a>
        <Link to="/home" className="authwall-return-home-container">
          Return to Home Instead
        </Link>
      </div>
    </div>
  );
}
