import Modal from "react-modal";
import { useState } from "react";
import { useCart } from "react-use-cart";
import Alert from "./alert";
import { useNavigate } from "react-router";
import PantryButton from "./PantryButton";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import useIncrementDecrement from "../pages/order/useIncrementDecrement";
import UserService from "../../services/user.service";
import EmailContainer from "./EmailContainer";
import useAuth from "../../helpers/useAuth";

const emailRegex = new RegExp(
  "^[A-Za-z0-9._%+-]+(@ucdavis.edu|@thepantry.ucdavis.edu)$"
);
const cartStyle = {
  content: {
    top: "50%",
    left: "50%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

/**

A React component for displaying a cart modal in the order page.
@param {Object} props - The component props.
@param {string} props.timeslotId - The ID of the selected timeslot.
@param {string} props.timeslotText - The text representation of the selected timeslot.
@returns {JSX.Element} The cart modal component.
*/
export default function CartModal({ timeslotId, timeslotText }) {
  const [cartOpen, setCartOpen] = useState(false);
  const { totalItems, items, emptyCart, removeItem } = useCart();
  const [alert, setAlert] = useState(null);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isEmailValid, setEmailValidState] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [orderSent, setOrderSent] = useState(false);
  const [orderChanges, setOrderChanges] = useState("");
  const { handleIncrement, handleDecrement } = useIncrementDecrement();
  const [loading, isLoading] = useState(false);
  const navigate = useNavigate();

  const { data: user, verified: userVerified } = useAuth();
  const [email, setEmail] = useState(user?.email || "");
  function sendOrder() {
    if (items.length < 1) {
      window.alert("Must order at least one item");
      isLoading(false);
      return;
    }

    if (emailRegex.test(email)) {
      setEmailValidState(true);

      UserService.createOrder(
        email,
        timeslotId,
        items.map((item) => {
          return {
            item: item.id,
            quantity: item.quantity,
            item_name: item.name,
          };
        })
      ).then((res) => {
        isLoading(false);

        // Build order modification string
        let changesStr = ``;
        if (res.data.errors) {
          changesStr =
            res.data.errors.length === 0
              ? ``
              : `<br><span class='text-danger fw-bold'>The following changes have been made to your order due to item availability:</span><br>`;

          res.data.errors.forEach((error, index) => {
            if (index === res.data?.errors.length - 1) {
              changesStr += `${error}`;
            } else {
              changesStr += `${error}<br>`;
            }
          });
        }

        if (res.status === 400) {
          // Orders placed within 24 hours of each other or timeslot reached capacity
          setAlert(res.data.header);
          setOrderChanges(changesStr);
          setOrderSent(false);
          setIsAlertOpen(true);
          setCartOpen(false);
        } else if (res.status === 200) {
          // Order successfully created
          setAlert(res.data.header);
          setOrderChanges(changesStr);
          setEmailSent(res.data.email_sent);
          setOrderSent(true);
          setIsAlertOpen(true);
          setCartOpen(false);
          emptyCart();
        }
      });
    } else {
      // Invalid email
      setAlert("Valid email must be provided!");
      setCartOpen(false);
      setIsAlertOpen(true);
      setEmailValidState(false);
      isLoading(false);
    }
  }

  return (
    <div>
      <Alert
        message={alert}
        description={
          orderSent
            ? emailSent
              ? `Your order <strong>#${alert
                  .replace("[Staff/Volunteer] ", "")
                  .replace("Order #", "")
                  .replace(
                    " Placed!\n",
                    ""
                  )} </strong>for <strong>${timeslotText} </strong> has been confirmed. Please verify that the confirmation email has been received, or write down the order number.<br>${orderChanges}`
              : `Your order <strong>#${alert
                  .replace("[Staff/Volunteer] ", "")
                  .replace("Order #", "")
                  .replace(
                    " Placed!\n",
                    ""
                  )} </strong>for <strong>${timeslotText} </strong> has been confirmed, <strong>but we were unable to send an email confirmation. Please write down your order details or screenshot this page.  <strong><br>${orderChanges}`
            : `${orderChanges}`
        }
        isOpen={isAlertOpen}
        setParentOpen={setIsAlertOpen}
        onAcknowledge={() => {
          if (isEmailValid && orderSent) {
            navigate("/");
          } else {
            setCartOpen(true);
            setIsAlertOpen(false);
            isLoading(false);
          }
        }}
      />
      <PantryButton onClick={() => setCartOpen(true)}>
        Your Cart ({totalItems})
      </PantryButton>
      <Modal
        isOpen={cartOpen}
        onRequestClose={() => setCartOpen(false)}
        style={cartStyle}
        contentLabel="Your Cart"
      >
        <div className="modal-header">
          <h4 className="food-bag">Food Bag</h4>
          <button className="btn btn-danger" onClick={() => setCartOpen(false)}>
            X
          </button>
        </div>
        <div className="modal-content">
          <EmailContainer
            email={email}
            setInput={setEmail}
            onConfirm={() => {
              let result = new RegExp(
                "^[A-Za-z0-9._%+-]+(@ucdavis.edu|@thepantry.ucdavis.edu)$"
              ).test(email);
              if (result) {
                setAlert(
                  <div
                    className="alert alert-success alert-dismissible fade show mt-2"
                    role="alert"
                  >
                    Your email has successfully been set to{" "}
                    <span className="alert-link">{email}</span>!
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="alert"
                      aria-label="Close"
                      onClick={() => setAlert(null)}
                    ></button>
                  </div>
                );
              } else {
                setEmail("");
                setAlert(
                  <div
                    className="alert alert-danger alert-dismissible fade show mt-2"
                    role="alert"
                  >
                    Please enter a valid UC Davis email address!
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="alert"
                      aria-label="Close"
                      onClick={() => setAlert(null)}
                    ></button>
                  </div>
                );
              }
            }}
            disabled={userVerified}
          >
            {alert}
          </EmailContainer>
          {totalItems === 0 ? (
            <>
              <p className="text-muted">Cart is empty.</p>
            </>
          ) : (
            <>
              <h3>Your Order</h3>
              <p>{totalItems} of 40 total items in food bag</p>
              <ol className="order-list">
                {items &&
                  items.map((item) => {
                    return (
                      <li key={item.id}>
                        <div className="item-container">
                          <h5>
                            {item.name} - {item.quantity}
                          </h5>
                          <div>
                            <button
                              className={`btn ${
                                item.quantity === 0
                                  ? "btn-secondary disabled"
                                  : "btn-warning"
                              }`}
                              onClick={() => {
                                handleDecrement(item);
                              }}
                            >
                              -
                            </button>
                            <button
                              className={`btn ${
                                item.quantity < item.limit && totalItems < 40
                                  ? "btn-primary"
                                  : "btn-secondary disabled"
                              }`}
                              onClick={() => {
                                handleIncrement(item);
                              }}
                            >
                              +
                            </button>
                            <button
                              className="btn btn-danger"
                              onClick={() => removeItem(item.id)}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      </li>
                    );
                  })}
              </ol>

              <div className="d-flex gap-5">
                <button
                  className="btn btn-danger"
                  onClick={() => {
                    confirmAlert({
                      title: "Emptying cart",
                      message:
                        "Emptying your cart will remove all of the items. Are you sure you want to empty your cart?",
                      buttons: [
                        {
                          label: "Yes",
                          onClick: () => emptyCart(),
                        },
                        {
                          label: "No",
                        },
                      ],
                    });
                  }}
                >
                  Empty Cart
                </button>{" "}
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    isLoading(true);
                    sendOrder();
                  }}
                  disabled={loading}
                >
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span>{!loading ? `Order Now` : ``}</span>
                </button>
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
}
