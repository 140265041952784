import { useCallback, useEffect, useRef, useState } from "react";
import PublicApiService from "../services/public-api.service";

/**
 * Allows read-only access to the timeslots. Automatically handles caching and loading
 * @param {boolean} [initialLoad=true] Set to false if you intend to manually reload all timeslots (to skip cache)
 * @return {Object} Array of timeslots in timeslots, loading, and a reloadTimeslots() function
 */
export function useTimeslots(initialLoad = true) {
  const [timeslots, setTimeslots] = useState([]);
  const [loading, setLoading] = useState(true);
  const initialLoadRef = useRef(initialLoad);
  const loadTimeslots = useCallback((skipCache = false) => {
    setLoading(true);
    PublicApiService.getTimeslots(skipCache)
      .then((loadedTimeslots) => setTimeslots(loadedTimeslots))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (initialLoadRef.current) {
      loadTimeslots();
    }
  }, [loadTimeslots]);

  return {
    reloadTimeslots: useCallback(() => loadTimeslots(true), [loadTimeslots]),
    timeslots: timeslots,
    loading: loading,
  };
}

/**
 * Allows read-only access to the valid timeslots for the upcoming week. Automatically handles caching and loading
 * @param {boolean} [initialLoad=true] Set to false if you intend to manually reload valid timeslots (to skip cache)
 * @return {Object} Object containing an array of valid timeslots, loading status, and a reloadValidTimeslots() function
 */
export function useValidTimeslots(initialLoad = true) {
  const [validTimeslots, setValidTimeslots] = useState([]);
  const [loading, setLoading] = useState(true);
  const initialLoadRef = useRef(initialLoad);
  const loadValidTimeslots = useCallback((skipCache = false) => {
    setLoading(true);
    PublicApiService.getValidTimeslots(skipCache)
      .then((loadedValidTimeslots) => setValidTimeslots(loadedValidTimeslots))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (initialLoadRef.current) {
      loadValidTimeslots();
    }
  }, [loadValidTimeslots]);

  return {
    reloadValidTimeslots: useCallback(
      () => loadValidTimeslots(true),
      [loadValidTimeslots]
    ),
    validTimeslots: validTimeslots,
    loading: loading,
  };
}

/**
 * Allows read-only access to the order items. Automatically handles caching and loading
 * @return {Object} Object containing array of items and loading status
 */
export function useItems() {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(
    Boolean(PublicApiService.itemsCache.lastRefresh)
  );
  useEffect(() => {
    PublicApiService.getItems()
      .then((loadedItems) => setItems(loadedItems))
      .finally(() => setLoading(false));
  }, []);

  return {
    items: items,
    loading: loading,
  };
}
