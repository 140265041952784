import { useParams } from "react-router-dom";
import StaffService from "../../../../services/staff.service";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import InventoryModal from "./inventory-modal";
import UserService from "../../../../services/user.service";
import { ORDER_STATUS_CHOICES } from "../../../../constants";

export default function EditOrder() {
  const [email, setEmail] = useState("");
  const [timeAdded, setTimeAdded] = useState("");
  const [timeSlot, setTimeSlot] = useState("");
  const [orderProducts, setOrderProducts] = useState([]);
  const [status, setStatus] = useState("");
  const [timeCollected, setTimeCollected] = useState(new Date().toISOString());
  const [showingTable, setShowingTable] = useState(false);
  const [inventory, setInventory] = useState();
  const [sending, isSending] = useState(false);

  // compare backend order to front end order
  // if they are different, send the front end one

  const params = useParams(); // Data passed in from url
  // const returnTo = new URLSearchParams(window.location.search).get('returnTo');

  let navigate = useNavigate();

  useEffect(() => {
    StaffService.fetchOrder(params.id).then((order) => {
      setEmail(order.email);
      setTimeAdded(order.time_added);
      setTimeSlot(order.time_slot);
      setOrderProducts(order.order_products);
      setStatus(order.status);
    });

    UserService.getPatronBoard()
      .then((res) => setInventory(res.data))
      .catch(console.err);
  }, [params.id]);

  function deleteOrderProduct(idx) {
    setOrderProducts(
      orderProducts.filter((product, innerIdx) => {
        if (innerIdx === idx) {
          product.item_name = "X";
          product.quantity = 0;
        }
        if (idx === 0) {
          return orderProducts.slice(1, orderProducts.length);
        }
        return idx;
      })
    );
  }

  function statusCheck(status, time) {
    return status === "PU" ? new Date().toISOString() : time;
  }

  function modifyItemQuantity(idx, e) {
    setOrderProducts(
      orderProducts.map((order, innerIdx) => {
        if (innerIdx === idx) {
          order.quantity = Number(e.target.value);
          if (isNaN(order.quantity)) {
            order.quantity = 0;
          }
        }

        return order;
      })
    );
  }

  function modifyItemName(idx, e) {
    setOrderProducts(
      orderProducts.map((order, innerIdx) => {
        if (innerIdx === idx) {
          order.item_name = e.target.value;
        }
        return order;
      })
    );
  }

  function onAddNew() {
    setShowingTable(true);
  }

  function addNewItem(item) {
    setOrderProducts((prev) => [...prev, item]);
  }

  // return to previous page
  const goBack = () => {
    window.history.back();
  };

  return (
    <>
      {showingTable ? (
        <InventoryModal
          setShowingTable={setShowingTable}
          showingTable={showingTable}
          addNewItem={addNewItem}
          orderProducts={orderProducts}
          inventory={inventory}
        />
      ) : (
        <></>
      )}

      <h2 className="text-center">Editing Order #{params.id}</h2>

      <div className="form-inputs form-group">
        <div className="form-group">
          <label>Email</label>
          <input
            type="text"
            placeholder="Required"
            className="form-control input-lg"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          ></input>
        </div>
      </div>

      <div className="form-inputs form-group">
        <div className="form-group">
          <label>Time Added</label>
          <input
            type="text"
            placeholder="Required"
            className="form-control input-lg"
            value={timeAdded}
            onChange={(e) => {
              setTimeAdded(e.target.value);
            }}
          ></input>
        </div>
      </div>

      <div className="form-inputs form-group">
        <div className="form-group">
          <label>Time Slot</label>
          <input
            type="number"
            placeholder="Required"
            className="form-control input-lg"
            value={timeSlot}
            onChange={(e) => {
              setTimeSlot(e.target.value);
            }}
          ></input>
        </div>
      </div>

      <div className="order-drop-down-group">
        <div className="form-group">
          <label>Status</label>
          <select
            className="form-select form-order"
            value={status}
            onChange={(e) => {
              setTimeCollected(statusCheck(e.target.value, timeCollected));
              setStatus(e.target.value);
            }}
          >
            {ORDER_STATUS_CHOICES.map((status, idx) => (
              <option value={status.code} key={idx}>
                {status.display}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="order-outer-container">
        <table className="table caption-top order-table-container table-responsive">
          <caption>Order Content</caption>
          <thead>
            <tr>
              <th scope="col">Item</th>
              <th scope="col">Quantity</th>
              <th scope="col">Remove</th>
            </tr>
          </thead>
          <tbody>
            {orderProducts.map((product, idx) => {
              return (
                <tr key={idx}>
                  <td>
                    <input
                      value={product.item_name}
                      onChange={(e) => {
                        modifyItemName(idx, e);
                      }}
                    ></input>
                  </td>
                  <td>
                    <input
                      value={product.quantity}
                      onChange={(e) => {
                        modifyItemQuantity(idx, e);
                      }}
                    ></input>
                  </td>
                  <td>
                    <button
                      className="btn btn-danger"
                      onClick={(e) => {
                        deleteOrderProduct(idx, e);
                      }}
                    >
                      X
                    </button>
                  </td>
                </tr>
              );
            })}
            <tr key="new">
              <td>
                <div>
                  <button
                    className="btn btn-success"
                    onClick={() => {
                      onAddNew();
                    }}
                  >
                    Add New Item
                  </button>
                </div>
              </td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <button
          className="mt-5 btn btn-primary"
          onClick={() => {
            // console.log("time used: " , timeCollected);
            const data = {
              email: email,
              timeAdded: timeAdded,
              scheduledTime: timeSlot,
              order_products: orderProducts,
              time_collected: timeCollected,
              status: status,
            };
            isSending(true);
            StaffService.editOrder(data, params.id)
              .then((response) => {
                if (response.status === 200) {
                  navigate("/staff/order");
                  //window.location.pathname = '/staff/order';
                } else if (response.status === 500) {
                  alert(response.statusText);
                }
                // Add more error checks here if needed

                isSending(false);
              })
              .catch((err) => {
                console.err(err);
                isSending(false);
              });
          }}
        >
          Finish Edit{" "}
          {sending && (
            <span className="spinner-border spinner-border-sm"></span>
          )}
        </button>
        <button className="mt-2 btn btn-danger" onClick={goBack}>
          Cancel
        </button>
      </div>
    </>
  );
}
