import React, { useState } from "react";
import { Link } from "react-router-dom";
import useAuth, { useRole } from "../../helpers/useAuth";
import AuthService from "../../services/auth.service";
import { CgProfile } from "react-icons/cg";
import { AUTH_URL } from "../../constants";
import PantryLogo from "../../resources/pantry-icon-color.png";
import "./AppNav.css";

export default function AppNav({ showNavLogo }) {
  const userRole = useRole();
  const showPlaceholder = !userRole || userRole === "Patron";

  return (
    <nav className="navbar navbar-expand w-100 d-flex flex-row justify-content-start align-items-stretch navbar-custom">
      <AppNavLogo show={showNavLogo} />
      <div
        className={`d-flex align-items-center w-100 position-relative ${
          showNavLogo ? "" : "custom-nav-pad"
        }`}
      >
        {showPlaceholder && <div className={`navbar-nav me-auto`} />}
        <AppNavStaffBoard className="d-none d-md-flex" />
        <AppNavVolunteerBoard className="d-none d-md-flex" />
        <AppNavLogin className="d-none d-md-flex" />
        <AppMobileNav noRelative={true} />
      </div>
    </nav>
  );
}

function AppNavVolunteerBoard({ className = "" }) {
  const userRole = useRole();
  const show = userRole.includes("Volunteer");

  if (!show) return null;
  return (
    <ul className={`navbar-nav me-auto align-items-center ${className}`}>
      <li className="rounded-navbar-button">
        <Link to="/staff/fulfill-orders">Volunteers</Link>
      </li>
    </ul>
  );
}

function AppNavStaffBoard({ className = "" }) {
  const userRole = useRole();
  const show = userRole.includes("Staff");

  if (!show) return null;
  return (
    <ul className={`navbar-nav me-auto align-items-center ${className}`}>
      <li className="rounded-navbar-button">
        <Link to="/staff">Inventory</Link>
      </li>
      <li className="rounded-navbar-button">
        <Link to="/staff/order">Orders</Link>
      </li>
      <li className="rounded-navbar-button">
        <Link to="/staff/timeslot">Timeslots</Link>
      </li>
      <li className="rounded-navbar-button">
        <Link to="/staff/fulfill-orders">Volunteers</Link>
      </li>
    </ul>
  );
}

function AppNavLogin({ className = "" }) {
  const { data: user } = useAuth();
  const logOut = AuthService.logout.bind(AuthService);

  if (user) {
    return (
      <div
        className={`navbar-nav mr-auto align-items-center gap-2 ${className}`}
      >
        <li className="rounded-navbar-button profile-nav-button">
          <Link to="/profile">
            <span className="profile-button">
              <CgProfile />
              {user.username}
            </span>
          </Link>
        </li>
        <li className="rounded-navbar-button">
          <a href="/" className="logout" onClick={logOut}>
            Log out
          </a>
        </li>
      </div>
    );
  }

  return (
    <ul className={`nav navbar-nav ml-auto ${className}`}>
      <li className="rounded-navbar-button no-hover">
        <span className="nav-link">Browsing as Guest</span>
      </li>
      <li className="rounded-navbar-button">
        <a href={AUTH_URL} className="nav-link">
          Login with Google
        </a>
      </li>
      {/* <li className="rounded-navbar-button">
        <Link to="/sign-in" className="nav-link">
          Already have an account?
        </Link>
      </li> */}
    </ul>
  );
}

function AppMobileNav({ className = "", noRelative = false }) {
  const { data: user } = useAuth();
  const userRole = useRole();
  const [toggled, setToggled] = useState(false);
  const tabIndex = toggled ? 0 : -1;
  const logOut = AuthService.logout.bind(AuthService);

  return (
    <div
      className={`d-flex d-md-none w-100 bg-gray justify-content-end align-items-center ${
        !noRelative ? "position-relative" : ""
      } ${className} ${toggled ? "mobile-nav-active" : ""}`}
    >
      <button
        className="border-0 mx-1 p-2 bg-transparent rounded-1 mobile-nav-button"
        onClick={() => setToggled(!toggled)}
      >
        <span className="mobile-nav-bar mobile-nav-top-bar" />
        <span className="mobile-nav-bar mobile-nav-center-bar" />
        <span className="mobile-nav-bar mobile-nav-bottom-bar" />
      </button>
      <ul
        className={`navbar nav w-100 flex-column mobile-navbar-ul position-absolute zindex-dropdown`}
      >
        {userRole.includes("Staff") && (
          <>
            <li>
              <Link to="/staff" className="nav-link" tabIndex={tabIndex}>
                Inventory
              </Link>
            </li>
            <li>
              <Link to="/staff/order" className="nav-link" tabIndex={tabIndex}>
                Orders
              </Link>
            </li>
            <li>
              <Link
                to="/staff/timeslot"
                className="nav-link"
                tabIndex={tabIndex}
              >
                Timeslots
              </Link>
            </li>
            <li>
              <Link
                to="/staff/fulfill-orders"
                className="nav-link"
                tabIndex={tabIndex}
              >
                Volunteers
              </Link>
            </li>
          </>
        )}
        {userRole.includes("Volunteer") && (
          <>
            <li>
              <Link
                to="/staff/fulfill-orders"
                className="nav-link"
                tabIndex={tabIndex}
              >
                Volunteers
              </Link>
            </li>
          </>
        )}
        {user ? (
          <>
            <li>
              <Link to="/profile" className="nav-link" tabIndex={tabIndex}>
                Your Profile
              </Link>
            </li>
            <li>
              <a
                href="/"
                className="nav-link"
                onClick={logOut}
                tabIndex={tabIndex}
              >
                Log out as {user.username}
              </a>
            </li>
          </>
        ) : (
          <>
            <li>
              <a href={AUTH_URL} className="nav-link" tabIndex={tabIndex}>
                Login with Google
              </a>
            </li>
            <li>
              <button
                className="bg-transparent border-0 nav-link"
                onClick={logOut}
                tabIndex={tabIndex}
              >
                Browsing as Guest
              </button>
            </li>
          </>
        )}
      </ul>
    </div>
  );
}

function AppNavLogo({ show }) {
  if (!show) return null;

  return (
    <Link to="/" className="nav-logo-container">
      <img className="shadow" src={PantryLogo} alt="Pantry Logo" />
    </Link>
  );
}
