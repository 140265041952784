import Select from "react-select";
import "./pageSelector.css";

/**

A React component for displaying a page selector with pagination functionality.
@param {Object} props - The component props.
@param {number} props.pageLimit - The total number of pages.
@param {number} props.currentPage - The currently selected page.
@param {Function} props.setPage - The function to set the selected page.
@returns {JSX.Element} The page selector component.
*/
export default function PageSelector({
  pageLimit = 1,
  currentPage = 1,
  setPage,
  showSearch = true,
}) {
  const maxPages = 5;
  const pageRange = Math.min(pageLimit, maxPages);

  // Calculate the start and end page numbers to display
  let startPage = currentPage - Math.floor(pageRange / 2);
  if (startPage < 1) {
    startPage = 1;
  }
  let endPage = startPage + pageRange - 1;
  if (endPage > pageLimit) {
    endPage = pageLimit;
    startPage = Math.max(1, endPage - pageRange + 1);
  }

  // Generate an array of page numbers to display
  const pages = Array.from({ length: pageRange }, (_, i) => startPage + i);
  const start = 1;
  const pageOptions = Array.from({ length: pageLimit }, (_, i) => ({
    value: start + i,
    label: `Page ${start + i}`,
  }));

  return (
    <div className="page-number-container">
      {showSearch && (
        <Select
          name="Search"
          className="basic-single"
          isSearchable={true}
        value={{
          value: currentPage,
          label: `Search Page`,
        }}
        menuPortalTarget={document.body}
        options={pageOptions}
        onChange={(value) => {
          setPage(value.value);
        }}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        
      /> )}
      <button
        type="button"
        className="btn btn-secondary"
        onClick={() => setPage(1)}
      >
        First Page
      </button>

      {/* Display "..." if the current page is not at the beginning of the range */}
      {startPage > 1 && (
        <>
          <span className="not-active-page">...</span>
        </>
      )}

      {pages.map((pageNum) => (
        <span
          key={pageNum}
          className={
            pageNum === currentPage ? "active-page" : "not-active-page"
          }
          onClick={() => {
            setPage(pageNum);
          }}
        >
          {pageNum}
        </span>
      ))}

      {/* Display "..." if the current page is not at the end of the range */}
      {endPage < pageLimit && (
        <>
          <span className="not-active-page">...</span>
        </>
      )}

      <button
        type="button"
        className="btn btn-secondary"
        onClick={() => setPage(pageLimit)}
      >
        Last Page: ({pageLimit})
      </button>
    </div>
  );
}
