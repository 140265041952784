import React, { useEffect, useState } from "react";
import UserService from "../../services/user.service";
import Select from "react-select";
import Loading from "../parts/Loading";
import useAuth from "../../helpers/useAuth";
import PublicApiService from "../../services/public-api.service";
import { ORDER_STATUS_CHOICES } from "../../constants";
import PantryButton from "../parts/PantryButton";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import PageSelector from "../parts/PageSelector";

const statusOptions = [
  { value: "PL", label: "Placed" },
  { value: "PU", label: "Picked up" },
  { value: "CD", label: "Cancelled" },
  { value: "NS", label: "No show" },
];

function DateString(day, month, date) {
  let Day = "";
  let Month = "";
  let Date = date;
  switch (day) {
    case 1:
      Day = "Monday";
      break;
    case 2:
      Day = "Tuesday";
      break;
    case 3:
      Day = "Wednesday";
      break;
    case 4:
      Day = "Thursday";
      break;
    case 5:
      Day = "Friday";
      break;
    case 6:
      Day = "Saturday";
      break;
    case 0:
      Day = "Sunday";
      break;
    default:
      Day = "";
      break;
  }
  switch (month) {
    case 0:
      Month = "January";
      break;
    case 1:
      Month = "February";
      break;
    case 2:
      Month = "March";
      break;
    case 3:
      Month = "April";
      break;
    case 4:
      Month = "May";
      break;
    case 5:
      Month = "June";
      break;
    case 6:
      Month = "July";
      break;
    case 7:
      Month = "August";
      break;
    case 8:
      Month = "September";
      break;
    case 9:
      Month = "October";
      break;
    case 10:
      Month = "November";
      break;
    case 11:
      Month = "December";
      break;
    default:
      Month = "";
      break;
  }

  return `${Day}, ${Month} ${Date}`;
}

function statusPicker(orderStatus) {
  const status = ORDER_STATUS_CHOICES.find(
    (choice) => choice.code === orderStatus
  );
  if (!status) {
    console.warn(`Invalid Order Status Code: ${orderStatus}`);
    return "";
  }

  // Stylistic choice: Replace hyphens with spaces
  return status.display.replace(/-/g, " ");
}

function OrderProductContainer({ products }) {
  const items = products.map((item) => {
    const itemText = `${item.item_name} x ${item.quantity}`;
    return <div key={itemText}>{itemText}</div>;
  });

  return <div>{items}</div>;
}

function OrderContainer({ filter, orders, page, maxPerPage }) {
  /* Filter order history by props.filter */
  const checkFilter = (item) => {
    return item.status === filter.value;
  };

  let arr =
    filter === null
      ? orders
          .slice()
          .reverse()
          .slice((page - 1) * maxPerPage, page * maxPerPage)
      : orders.slice().reverse().filter(checkFilter);
  const ordersList = arr.map((item) => {
    return (
      <li
        key={item.id}
        className="list-group-item d-flex justify-content-between align-items-start"
      >
        <div className="ms-2 me-auto">
          <div className="fw-bold">{`${new Date(
            item.time_added
          ).toLocaleDateString()} - #${item.id}`}</div>
          <OrderProductContainer products={item.order_products} />
          {item.status === "PL" ? (
            <div>
              <div className="fw-bold text-primary">
                {`Pickup on ${DateString(
                  new Date(item.time_slot.start_time).getDay(),
                  new Date(item.time_slot.start_time).getMonth(),
                  new Date(item.time_slot.start_time).getDate()
                )} ${new Date(item.time_slot.start_time).toLocaleTimeString(
                  "en-US",
                  {
                    timeStyle: "short",
                  }
                )} - ${new Date(item.time_slot.end_time).toLocaleTimeString(
                  "en-US",
                  {
                    timeStyle: "short",
                  }
                )}`}
              </div>
              <a
                href={`cancel-order/${item.order_id}`}
                className="fw-bold text-danger"
                target="_blank"
                rel="noreferrer"
              >
                {`Cancel Order`}
              </a>
            </div>
          ) : null}
        </div>
        <span
          className={`mb-auto badge 
        ${
          item.status === "PL"
            ? "bg-primary"
            : item.status === "CD"
            ? "bg-danger"
            : item.status === "PU"
            ? "bg-success"
            : item.status === "NS"
            ? "bg-warning"
            : "bg-primary"
        } 
        rounded-pill`}
        >
          {statusPicker(item.status)}
        </span>
      </li>
    );
  });

  return <ol className="list-group py-2">{ordersList}</ol>;
}

export default function Profile() {
  const { data: user } = useAuth();
  const [orderHistory, setOrderHistory] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [loading, isLoading] = useState(false);

  // Pagination hooks
  const [page, setPage] = useState(1);
  const [maxPerPage, setMaxPerPage] = useState(5);

  // On component mount, initialize current user and their order history
  useEffect(() => {
    const init = async () => {
      isLoading(true); // Set loading state
      // Batch fetch API data
      const [timeslotArr, orderArr] = await Promise.all([
        PublicApiService.getTimeslots(),
        UserService.viewOrders(),
      ]);

      // Convert timeslot id in orderArr to timeslot data
      // orderArr.map((order) => {
      //   order.time_slot = timeslotArr.find((ts) => ts.id === order.time_slot);
      //   return order;
      // });

      // Sort orders by timeslot field
      // orderArr.sort(function (a, b) {
      //   return new Date(b.start_time) - new Date(a.start_time);
      // });

      // Sort orders by order id
      orderArr.sort((a, b) => a.id - b.id);

      // Set state data
      setOrderHistory(orderArr);
      isLoading(false); // Unset loading state
    };
    init();
  }, []);

  function onPageDecrement() {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  }

  function onPageIncrement() {
    if (page < Math.ceil(orderHistory.length / maxPerPage)) {
      setPage((prevPage) => prevPage + 1);
    }
  }

  // Only render if currentUser state hook has finished initializing in useEffect
  if (user) {
    return (
      <div className="pb-4 pt-md-2">
        <header className="jumbotron">
          <h3>
            <strong>Account Information</strong>
          </h3>
        </header>
        <ol className="list-group">
          <li className="list-group-item d-flex justify-content-between align-items-start">
            <div className="ms-2 me-auto">
              <div className="fw-bold">Username</div>
              {user.username}
            </div>
          </li>
          <li className="list-group-item d-flex justify-content-between align-items-start">
            <div className="ms-2 me-auto">
              <div className="fw-bold">Email</div>
              {user.email}
            </div>
          </li>
          <li className="list-group-item d-flex justify-content-between align-items-start">
            <div className="ms-2 me-auto">
              <div className="fw-bold">Role</div>
              {user.role}
            </div>
          </li>
        </ol>
        <header className="jumbotron mt-5">
          <h3>
            <strong>Your Orders</strong>
          </h3>
        </header>

        <div className="bottom-interact-container mt-1 mb-1">
          <span>
            Show entries:
            <select
              className="form-select"
              onChange={(e) => {
                setMaxPerPage(e.target.value);
                setPage(1)
              }}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
            </select>
          </span>
        </div>

        <Select
          className="basic-single"
          isClearable={true}
          isSearchable={true}
          defaultValue={selectedOption}
          placeholder={"Filter by status"}
          options={statusOptions}
          onChange={setSelectedOption}
        />
        {orderHistory.length === 0 && !loading ? (
          <div>No order history</div>
        ) : ( 
          <OrderContainer     
            orders={orderHistory}
            filter={selectedOption}
            page={page}
            maxPerPage={maxPerPage}
          />
        )}
        {loading ? <Loading /> : null}

        <div className="pagination-container">
          <div className="arrow-container">
            <PantryButton onClick={onPageDecrement}>
              <AiOutlineArrowLeft />
            </PantryButton>
            <PantryButton onClick={onPageIncrement}>
              <AiOutlineArrowRight />
            </PantryButton>
          </div>

          <PageSelector
            pageLimit={Math.ceil(orderHistory.length / maxPerPage)}
            currentPage={page}
            setPage={setPage}
          />
        </div>
      </div>
    );
  }
}
