import { useEffect, useState } from "react";
import StaffService from "../../../../services/staff.service";
import ItemModal from "../../../units/item-modal";
import { useNavigate } from "react-router";
import DatePicker from "react-datepicker";
import "./staff-order.css";
import "react-datepicker/dist/react-datepicker.css";
import { confirmAlert } from "react-confirm-alert";
import { HiChevronUp, HiChevronDown, HiSelector } from "react-icons/hi";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import "react-confirm-alert/src/react-confirm-alert.css";
import Select from "react-select";
import Loading from "../../../parts/Loading";
import PantryButton from "../../../parts/PantryButton";
import PublicApiService from "../../../../services/public-api.service";
import PageSelector from "../../../parts/PageSelector";

/* Search filter options */
const filterOptions = [
  { value: "ID", label: "ID" },
  { value: "Email", label: "Email" },
  { value: "Date", label: "Date" },
  { value: "Item", label: "Item" },
];

/* Order status options */
const statusChoices = {
  PL: "Placed",
  CD: "Cancelled",
  PU: "Picked Up",
  NS: "No Show",
};

/* Order status sort order */
const statusSort = ["PL", "CD", "PU", "NS"];

/* Table filter options */
const sortOptions = {
  id: "id",
  timeslot: "timeslot",
  date: "date",
  time: "time",
};

/**
 * @description Constructs the staff order page
 * @returns JSX.Element
 */
export default function StaffOrder() {
  const [searchItem, setSearchItem] = useState("");
  const [orders, setOrders] = useState([]);
  const [fetchState, setFetchState] = useState(false);
  const [startMonth, setStartMonth] = useState(new Date());
  const [startDate, setStartDate] = useState(null);
  const [selectedOption, setSelectedOption] = useState(filterOptions[0]);
  const [loading, isLoading] = useState(false);

  // Table filter hooks
  const [sortAscending, setSortAscending] = useState(false);
  const [activeSort, setActiveSort] = useState(sortOptions.id);

  // Pagination hooks
  const [page, setPage] = useState(1);
  const [maxPerPage, setMaxPerPage] = useState(10);

  let navigate = useNavigate();

  useEffect(() => {
    const init = async () => {
      // Set loading state
      isLoading(true);

      // Batch fetch API data
      const [orderArr, timeslotArr] = await Promise.all([
        StaffService.fetchOrders(),
        PublicApiService.getTimeslots(),
      ]);

      // Convert timeslot id to timeslot data

      // map each timeslot to its id
      const timeslotMap = new Map(timeslotArr.map((ts) => [ts.id, ts]));
      // now get the timeslot by using the id from order
      orderArr.forEach((order) => {
        order.time_slot = timeslotMap.get(order.time_slot);
      });

      // Set state data
      setOrders(orderArr);
      isLoading(false);
    };
    init();
  }, [fetchState]);

  const toggleSortBy = (option) => {
    if (activeSort === option) {
      setSortAscending(!sortAscending);
      return;
    }
    setActiveSort(option);
    setSortAscending(true);
  };

  const deleteOrder = (targetOrder) => {
    StaffService.deleteOrder(targetOrder);
    setFetchState(!fetchState); // Switch
  };

  /* Search filter by selectedOption */
  const searchFilter = (order) => {
    if (searchItem === null && selectedOption.value !== "Date") {
      return true;
    }

    switch (selectedOption.value) {
      case "Email": // Email
        return !searchItem || order.email.includes(searchItem.toLowerCase());

      case "Date": // Date
        return (
          startDate == null ||
          startDate.toLocaleDateString() ===
            new Date(order.time_slot.start_time).toLocaleDateString()
        );

      case "Item": // Item
        // Parse ";" delimited input string
        // Remove "" and " " elements and leading and trailing spaces
        const searchArr = searchItem
          .toLowerCase()
          .split(";")
          .map((item) => item.trim())
          .filter((item) => item !== "" && item !== " ");

        /* Build orderArr, contains array of item names for this order */
        const orderArr = order.order_products.map((item) =>
          item.item_name.toLowerCase()
        );

        /* Check if searchArr string exists in orderArr, if it doesn't set flag to false */
        return searchArr.every((item) =>
          orderArr.some((orderItem) => orderItem.includes(item))
        );
      default: // ID
        return order.id.toString().includes(searchItem);
    }
  };

  /* Sorting for table's filter arrows */
  function sortFunc(a, b) {
    let timeA, timeB, idA, idB, statusA, statusB;
    switch (activeSort) {
      case sortOptions.timeslot: // Sort by timeslot
        timeA = new Date(a.time_slot.start_time).getTime();
        timeB = new Date(b.time_slot.start_time).getTime();
        return sortAscending ? timeA - timeB : timeB - timeA;
      case sortOptions.date: // Sort by date created
        timeA = new Date(a.time_added).getTime();
        timeB = new Date(b.time_added).getTime();
        return sortAscending ? timeA - timeB : timeB - timeA;
      case sortOptions.time: // Sort by time collected
        timeA = new Date(a.time_collected).getTime();
        timeB = new Date(b.time_collected).getTime();
        return sortAscending ? timeA - timeB : timeB - timeA;
      case sortOptions.id: // Sort by ID
        idA = a.id;
        idB = b.id;
        return sortAscending ? idA - idB : idB - idA;
      case sortOptions.status: // Sort by status
        statusA = statusSort.indexOf(a.status);
        statusB = statusSort.indexOf(b.status);
        return sortAscending ? statusA - statusB : statusB - statusA
      default: // Set default case as sorting by ID
        idA = a.id;
        idB = b.id;
        return sortAscending ? idA - idB : idB - idA;
    }
  }

  const filteredOrders = orders
    .filter(searchFilter)
    .sort((a, b) => sortFunc(a, b));

  function onPageDecrement() {
    if (page > 1) {
      setPage(Math.max(1, page - 1));
    }
  }

  function onPageIncrement() {
    if (page < Math.ceil(filteredOrders.length / maxPerPage)) {
      setPage(page + 1);
    }
  }

  const pageOrders = filteredOrders.slice(
    (page - 1) * maxPerPage,
    page * maxPerPage
  );

  return (
    <>
      <h1 className="text-center" id="top-of-page">
        Orders
      </h1>
      <div className="staff-order-search-container">
        <span>
          <DatePicker
            selected={startMonth}
            onChange={(date) => setStartMonth(date)}
            dateFormat="MM/yyyy"
            showMonthYearPicker
          />
          <button
            className="btn btn-primary mt-1"
            onClick={() => {
              // sends date in YYYY-MM format
              // StaffService.createSpreadsheet(
              //   startMonth.getFullYear() +
              //     "-" +
              //     ("0" + (startMonth.getMonth() + 1)).slice(-2)
              // );
              const selectedYear = startMonth.getFullYear();
              
              window.open(
                `https://docs.google.com/spreadsheets/d/101UjbUg__H-PCHDQYwYsjjMVEgyR08clbkHpmj5zI8Q/gviz/tq?tqx=out:csv&sheet=${(startMonth.toLocaleString('default', { month: 'long' }))} ${selectedYear}`,
                "_blank"
              );
            }}
          >
            {`Export ${startMonth.toLocaleString("en-us", {
              month: "short",
              year: "numeric",
            })}`}
          </button>
          <button
            className="btn btn-success mt-1 ms-2"
            onClick={() => navigate("/staff/add-order")}
          >
            Add Order
          </button>
        </span>
        <span className="staff-order-search-box">
          <Select
            className="basic-single"
            isSearchable={true}
            defaultValue={filterOptions[0]}
            options={filterOptions}
            onChange={(filter) => {
              if (selectedOption.value !== "Date") {
                document.getElementById("search-input").value = "";
                setSearchItem("");
              }
              setSelectedOption(filter);
            }}
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          />
          {selectedOption.value === "Date" ? (
            <DatePicker
              placeholderText="Click to select a date"
              selected={startDate ? startDate : null}
              isClearable={true}
              onChange={setStartDate}
            />
          ) : (
            <div className="input-group">
              <input
                className="form-control"
                id="search-input"
                type="text"
                placeholder={`Search by ${selectedOption.label}`}
                onChange={(e) => {
                  setPage(1);
                  setSearchItem(e.target.value);
                }}
                autoComplete="off"
              />
              <button
                className="btn btn-outline-danger"
                type="button"
                onClick={() => {
                  // Clear input field
                  document.getElementById("search-input").value = "";
                  setSearchItem("");
                }}
              >
                Clear
              </button>
            </div>
          )}
        </span>
      </div>
      <div className="bottom-interact-container mt-1 mb-1">
        <span>
          Show entries:
          <select
            className="form-select"
            onChange={(e) => {
              setMaxPerPage(e.target.value);
            }}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </span>
      </div>

      <section className="table-responsive">
        <StaffOrderTable
          loading={loading && orders.length === 0}
          displayedOrders={pageOrders}
          setSortBy={toggleSortBy}
          deleteOrder={deleteOrder}
          toggleSortBy={toggleSortBy}
          activeSort={activeSort}
          sortAscending={sortAscending}
        />
        <div className="pagination-container">
          <div className="arrow-container">
            <PantryButton onClick={onPageDecrement}>
              <AiOutlineArrowLeft />
            </PantryButton>
            <PantryButton onClick={onPageIncrement}>
              <AiOutlineArrowRight />
            </PantryButton>
          </div>

          <PageSelector
            pageLimit={Math.ceil(filteredOrders.length / maxPerPage)}
            currentPage={page}
            setPage={setPage}
          />
          <a href="#top-of-page" style={{ textDecoration: "none" }}>
            <b>Jump to top</b>
          </a>
        </div>
      </section>
    </>
  );
}

export function StaffOrderTable({
  loading,
  displayedOrders,
  deleteOrder,
  toggleSortBy,
  activeSort,
  sortAscending,
}) {
  return (
    <table className="table caption-top">
      <StaffOrderTableHeader
        toggleSortBy={toggleSortBy}
        activeSort={activeSort}
        sortAscending={sortAscending}
      />
      <tbody>
        {loading && (
          <tr>
            <td colSpan={100}>
              <Loading />
            </td>
          </tr>
        )}
        {displayedOrders.map((order) => {
          return (
            <StaffOrderTableEntry
              key={order.id}
              order={order}
              deleteOrder={deleteOrder}
            />
          );
        })}
      </tbody>
    </table>
  );
}

function StaffOrderTableHeader({ toggleSortBy, activeSort, sortAscending }) {
  return (
    <thead>
      <tr>
        <th scope="col">
          <button
            className="sort-button"
            onClick={() => {
              toggleSortBy(sortOptions.id);
            }}
          >
            <b>ID</b>
            {activeSort === sortOptions.id ? (
              !sortAscending ? (
                <HiChevronDown />
              ) : (
                <HiChevronUp />
              )
            ) : (
              <HiSelector />
            )}
          </button>
        </th>
        <th scope="col">
          <button
            className="sort-button"
            onClick={() => {
              toggleSortBy(sortOptions.timeslot);
            }}
          >
            <b>Timeslot</b>
            {activeSort === sortOptions.timeslot ? (
              !sortAscending ? (
                <HiChevronDown />
              ) : (
                <HiChevronUp />
              )
            ) : (
              <HiSelector />
            )}
          </button>
        </th>
        <th scope="col">
          <button
              className="sort-button"
              onClick={() => {
                toggleSortBy(sortOptions.status);
              }}
            >
              <b>Status</b>
              {activeSort === sortOptions.status ? (
                !sortAscending ? (
                  <HiChevronDown />
                ) : (
                  <HiChevronUp />
                )
              ) : (
                <HiSelector />
              )}
          </button>
        </th>
        <th scope="col">
          <button
            className="sort-button"
            onClick={() => {
              toggleSortBy(sortOptions.date);
            }}
          >
            <b>Date Created</b>
            {activeSort === sortOptions.date ? (
              !sortAscending ? (
                <HiChevronDown />
              ) : (
                <HiChevronUp />
              )
            ) : (
              <HiSelector />
            )}
          </button>
        </th>
        <th scope="col">
          <button
            className="sort-button"
            onClick={() => {
              toggleSortBy(sortOptions.time);
            }}
          >
            <b>Time Collected</b>
            {activeSort === sortOptions.time ? (
              !sortAscending ? (
                <HiChevronDown />
              ) : (
                <HiChevronUp />
              )
            ) : (
              <HiSelector />
            )}
          </button>
        </th>
        <th scope="col">Contents</th>
        <th scope="col">Email</th>
        <th scope="col">Operation</th>
      </tr>
    </thead>
  );
}

function StaffOrderTableEntry({ order, deleteOrder }) {
  const navigate = useNavigate();
  const timeslotTimeString = `${new Date(
    order.time_slot.start_time
  ).toLocaleDateString()} ${new Date(
    order.time_slot.start_time
  ).toLocaleTimeString("en-US", {
    timeStyle: "short",
  })} - ${new Date(order.time_slot.end_time).toLocaleTimeString("en-US", {
    timeStyle: "short",
  })} `;

  const timeAddedString = `${new Date(
    order.time_added
  ).toLocaleDateString()} ${new Date(order.time_added).toLocaleTimeString(
    "en-US",
    {
      timeStyle: "short",
    }
  )}`;

  const timeCollectedString =
    order.status === "PU"
      ? `${new Date(order.time_collected).toLocaleDateString()} ${new Date(
          order.time_collected
        ).toLocaleTimeString("en-US", {
          timeStyle: "short",
        })}`
      : "N/A";

  const onEdit = () => {
    navigate(`/staff/edit-order/${order.id}?returnTo=Orders`);
  };

  const onDelete = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="ui-modal-primary">
            <h1>Are you sure?</h1>
            <p>
              This will remove the item <b>permanently.</b>
            </p>
            <span className="d-flex gap-2">
              <button
                className="btn btn-danger"
                onClick={() => {
                  deleteOrder(order.id);
                  onClose();
                }}
              >
                Remove
              </button>
              <button className="btn btn-secondary" onClick={onClose}>
                Cancel
              </button>
            </span>
          </div>
        );
      },
    });
  };

  return (
    <tr>
      <td>{order.id}</td>
      <td>{timeslotTimeString}</td>
      <td>{statusChoices[order.status]}</td>
      <td className="text-truncate">{timeAddedString}</td>
      <td className="text-truncate">{timeCollectedString}</td>
      <td>
        <ItemModal items={order.order_products} />
      </td>
      <td>{order.email}</td>
      <td className="d-flex gap-2 border-bottom-0">
        <button className="btn btn-warning" onClick={onEdit}>
          Edit
        </button>
        <button className="btn btn-danger" onClick={onDelete}>
          X
        </button>
      </td>
    </tr>
  );
}
