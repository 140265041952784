import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Alert from "../parts/alert";
import PublicApiService from "../../services/public-api.service";
import UserService from "../../services/user.service";
import useAuth from "../../helpers/useAuth";

export default function CancelOrder() {
  const navigate = useNavigate();
  const { data: user } = useAuth();
  const { id } = useParams(); // Data passed in from url
  const [order, setOrder] = useState(null);

  const [alertMsg, setAlertMsg] = useState(null);
  const [alertDescription, setAlertDescription] = useState(null);

  const onAcknowledge = () => {
    setAlertMsg(null);
    navigate("/");
  };

  useEffect(() => {
    UserService.cancelOrderPreview(id, user.email)
      .then((res) => {
        setOrder(res.data);
      })
      .catch((err) => {
        if (err.response.status === 404) {
          setAlertMsg("This order has already been canceled!");
          setAlertDescription(
            "If this was a mistake, please place another order on our ordering page. If this was a bug, please contact The Pantry with any questions or concerns."
          );
        } else if (err.response.status !== 200) {
          alert(`Unexpected Response (See Console)`);
          console.error(err);
        }
      });
  }, [id, user.email]);

  if (order) {
    return <CancelOrderConfirm order={order} />;
  }

  return (
    <>       
    <Alert
      message={alertMsg}
      description={alertDescription}
      isOpen={Boolean(alertMsg)}
      setParentOpen={() => setAlertMsg(null)}
      onAcknowledge={onAcknowledge} /> 
      <div>Loading Order...</div> 
    </>);
}

function CancelOrderConfirm({ order }) {
  const orderProducts = order.order_products;
  const navigate = useNavigate();
  const [alertMsg, setAlertMsg] = useState(null);
  const [alertDescription, setAlertDescription] = useState(null);
  const [timeSlot, setTimeSlot] = useState(null);
  const [cancelled, setCancelled] = useState(false);
  useEffect(() => {
    PublicApiService.getSingleTimeslot(order.time_slot).then((timeslot) =>
      setTimeSlot(timeslot)
    );
  }, [order.time_slot]);

  const onCancelClick = () => {
    UserService.cancelOrder(order.order_id, order.email)
      .then(() => {
        setCancelled(true);
        setAlertMsg("Your order has been canceled!");
        setAlertDescription(
          "If this was a mistake, please place another order on our ordering page. If this was a bug, please contact The Pantry with any questions or concerns."
        );
      })
      .catch((err) => {
        setAlertMsg(`Unable to cancel order: ${err.code} ${err.message}`);
      });
  };

  const onAcknowledge = () => {
    setAlertMsg(null);
    if (cancelled) {
      navigate("/");
    }
  };

  if (order.status === "CD") {
    return "Already Cancelled";
  }

  if (order.status !== "PL") {
    return "Order has already been marked off. You may place another order normally";
  }

  return (
    <>
      <Alert
        message={alertMsg}
        description={alertDescription}
        isOpen={Boolean(alertMsg)}
        setParentOpen={() => setAlertMsg(null)}
        onAcknowledge={onAcknowledge}
      />
      <h1 className="text-center">Cancel Order?</h1>
      {timeSlot && (
        <p className="text-center">
          {" "}
          Your Pickup time:{" "}
          {`${new Date(timeSlot.start_time).toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
          })} - ${new Date(timeSlot.end_time).toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
          })}`}
        </p>
      )}
      <p className="text-center">Email: {order.email}</p>
      <section className="table-responsive">
        <table className="table caption-top">
          <caption>Items</caption>
          <thead>
            <tr>
              <th scope="col">Item Name</th>
              <th scope="col">Quantity</th>
            </tr>
          </thead>
          <tbody>
            {orderProducts.map((item) => {
              return (
                <tr key={item.item_name}>
                  <td>{item.item_name}</td>
                  <td>{item.quantity}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </section>
      <div className="d-flex justify-content-center">
        <button className="btn btn-danger" onClick={onCancelClick}>
          Cancel Order
        </button>
      </div>
    </>
  );
}
