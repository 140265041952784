import "./home/home.css";
import "./abbyrlFools.css";

export default function AbbyrlFools() {
  document.getElementsByTagName("body")[0].classList.add("abbyrl-fools");

  return (
    <section>
      <h1 className="fs-2 fw-bold text-center w-100">Abby Ordering</h1>
      <div className="d-flex align-items-center flex-column order-1 order-md-0 py-1 mb-3">
        <div className="fw-bold text-center px-1">Submit to Abby Hours</div>
        <div className="d-flex flex-column align-items-center">
          <div className="text-center px-1">
            M/W/F 10:30AM - 4:00PM
            <span className="d-none d-md-inline"></span>
          </div>
          <div className="text-center px-1">
            T/R 9:30AM - 4:00PM<span className="d-none d-md-inline"></span>
          </div>
          <div className="text-center px-1">
            Weekends 12:00PM - 2:00PM (Parasocial Contact Only)
          </div>
        </div>
      </div>

      <div className="more-info">
        <h3 className="text-center mb-4">ABOUT OUR OVERLORD</h3>
        <div>
          <div className="fw-bold text-center px-1">Who We Are</div>
          <div className="container sub-header text-center px-0 px-md-2 mb-3">
            The Pantry is open to all students, staff, and faculty who submit to
            our overlord Abigail Nonnarath. This also includes graduate, PhD,
            and postdoctoral students. We're here to aid UC Davis Students in
            their pursuit of happiness by ensuring that no student will ever
            know a life without our benevolent overlord, Abby due to lack of
            knowledge of her power.
          </div>
          <div className="d-flex align-items-center flex-column order-1 order-md-0 py-1">
            <div className="fw-bold text-center px-1">Location</div>
            <div className="d-flex flex-column flex-md-row">
              <div className="text-center px-1">
                Memorial Union 167 (next to Aggie Compass)
                <br />
                Remember to send an email to give thanks to our overlord:
                <br />
                <del>(today and today ONLY!)</del>
                <br />
                <i>
                  You have missed the deadline to prove your faith in our
                  benevolent overlord! Quick– before it's too late!
                </i>
                <br />
                <i>
                  Repent in the channel you found this webpage from with "
                  <span style={{ userSelect: "all" }}>
                    @Abigail I must display my late appreciation of your
                    excellence! I pleadeth thee shall blacklist me not 'from all
                    Pantry-affiliated and non-Pantry-affiliated affairs for
                    heresy' as did state by the recent E. Mail!
                  </span>
                  "
                </i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
