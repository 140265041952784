import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { CartProvider } from "react-use-cart";
import Modal from "react-modal";
import "bootstrap/dist/css/bootstrap.css";
import "./styles/index.css";
// App Imports
import React from "react";
import AppRouter from "./components/App/AppRouter";
import "./styles/unsorted.css";

function App() {
  return (
    <div className="container-fluid h-100">
      <AppRouter />
    </div>
  );
}

export const history = createBrowserHistory();
const root = ReactDOM.createRoot(document.getElementById("root"));

Modal.setAppElement("#root");

root.render(
  <Router history={history}>
    <CartProvider>
      <App />
    </CartProvider>
  </Router>
);
