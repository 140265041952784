import { useParams } from "react-router-dom";
import StaffService from "../../../../services/staff.service";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
// import "./volunteer-edit-order.css"

export default function VolunteerEditOrder() {
  const [orderProducts, setOrderProducts] = useState([]);
  const [sending, isSending] = useState(false);

  const params = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    StaffService.fetchOrder(params.id).then((order) => {
      setOrderProducts(order.order_products);
    });
  }, [params.id]);

  function modifyItemQuantity(idx, e) {
    setOrderProducts(
      orderProducts.map((order, innerIdx) => {
        if (innerIdx === idx) {
          order.quantity = Number(e.target.value);
          if (isNaN(order.quantity)) {
            order.quantity = 0;
          }
        }

        return order;
      })
    );
  }

  return (
    <>
      <h2 className="text-center">Editing Order #{params.id}</h2>

      <div className="order-outer-container">
        <table className="table caption-top order-table-container table-responsive">
          <caption>Order Content</caption>
          <thead>
            <tr>
              <th scope="col">Item</th>
              <th scope="col">Quantity</th>
            </tr>
          </thead>
          <tbody>
            {orderProducts.map((product, idx) => {
              return (
                <tr key={idx}>
                  <td>
                    <label>{product.item_name}</label>
                  </td>
                  <td>
                    <input
                      value={product.quantity}
                      onChange={(e) => {
                        modifyItemQuantity(idx, e);
                      }}
                    ></input>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <button
          className="mt-5 btn btn-primary"
          onClick={() => {
            const data = {
              order_products: orderProducts,
            };
            isSending(true);
            StaffService.volunteerEditOrder(data, params.id)
              .then((response) => {
                if (response.status === 200) {
                  navigate("/staff/fulfill-orders");
                } else if (response.status === 500) {
                  alert(response.statusText);
                }
                isSending(false);
              })
              .catch((err) => {
                console.err(err);
                isSending(false);
              });
          }}
        >
          Finish Edit{" "}
          {sending && (
            <span className="spinner-border spinner-border-sm"></span>
          )}
        </button>
      </div>
    </>
  );
}
