import { Link } from "react-router-dom";
import WalkIn from "../../../resources/walk-in.svg";
import OrderOnline from "../../../resources/order-online.svg";
import "./home.css";

export default function Home() {
  return (
    <div className="pb-4 pt-md-2 mx-0">
      <div className="container-fluid d-flex flex-column justify-content-between align-items-center navbar-header mb-3 px-0 px-md-5">
        <HomeHeaderSection />
        <HomeButtonSection />
      </div>
    </div>
  );
}

function HomeHeaderSection() {
  return (
    <>
      <h1 className="fs-2 fw-bold text-center w-100">Online Ordering</h1>
      <div className="d-flex align-items-center flex-column order-1 order-md-0 py-1 mb-3">
        <div className="fw-bold text-center px-1">Hours and Information:</div>
        <div className="d-flex flex-column align-items-center">
          <div className="text-center px-1">
            <a href="https://thepantry.ucdavis.edu/">ASUCD Website</a>
          </div>
        </div>
      </div>
    </>
  );
}

function HomeButtonSection() {
  return (
    <div className="row justify-content-around">
      {/*PREORDER*/}
      <HomeButtonOption
        labelText="Pre-order Non-perishables"
        image={OrderOnline}
        to="/order"
      >
        <p className="btn-text">
          <span className="fw-bold">Pre-order: </span>
          We pack your non-perishables on the spot! This includes items such as
          canned goods, toiletries and more.
          <br />
          <span className="fst-italic">No same day appointments.</span>
        </p>
      </HomeButtonOption>

      {/*VIEW WALK IN MENU */}
      <HomeButtonOption
        labelText="View Walk-in Menu"
        image={WalkIn}
        to="https://abrupt-nightshade-be6.notion.site/Walk-In-Menu-443afebccb974648b313b71a9815001e"
        absolute={true}
        target="_blank"
      >
        <p className="btn-text">
          <span className="fw-bold">Walk-In:</span> We offer fresh produce,
          dairy, and nonperishable items!
        </p>
        <p className="btn-text">
          We restock every{" "}
          <span className="fw-bold">Monday, Wednesday, and Friday.</span> Check
          our menu to see what goodies we have in stock!
        </p>
      </HomeButtonOption>
    </div>
  );
}

// Can't assign <a> tag to a variable unless it's a component
const ATag = ({ children, ...props }) => <a {...props}>{children}</a>;

function HomeButtonOption(props) {
  const {
    labelText,
    className = "",
    absolute,
    to,
    target,
    children,
    image,
    ...extraProps
  } = props;

  const LinkTag = absolute ? ATag : Link;

  return (
    <div
      className={`col-10 col-md-6 col-xl-4 d-flex flex-column justify-content-start align-items-center px-3 px-xl-2 ${className}`}
      {...extraProps}
    >
      <LinkTag
        className="row w-100 d-flex flex-column justify-content-center align-items-center rounded-4 mb-3 button-columns"
        href={absolute ? to : undefined}
        to={absolute ? undefined : to}
        target={target}
      >
        <div className="fw-bold fs-5 mb-5 px-2 pb-1 text-center preorder-text">
          {labelText}
        </div>
        <img className="w-75 button-logo" src={image} alt="Logo"></img>
      </LinkTag>

      <div className="row w-100">{children}</div>
    </div>
  );
}
