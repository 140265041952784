import { useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router";
import { addDays, startOfWeek, endOfWeek } from "date-fns";
import { useValidTimeslots } from "../../helpers/publicAPIHooks";
import PantryButton from "../parts/PantryButton";
import Loading from "../parts/Loading";
import "react-datepicker/dist/react-datepicker.css";
import "./timeslot.css";

export default function SchedulingForm() {
  const navigate = useNavigate();

  return (
    <div className="mb-5">
      <div className="d-flex justify-content-center justify-content-md-start">
        <PantryButton
          className="mt-2 mb-4 mt-md-0 mb-md-0"
          onClick={() => {
            navigate("/");
          }}
        >
          Back
        </PantryButton>
      </div>
      <div className="container d-flex justify-content-center align-items-center flex-column">
        <p className="main-heading text-center fw-bold mt-3">
          Select a pickup time.
        </p>
        <p className="main-subheading text-center mb-5">
          Select an available date and time, then click{" "}
          <span className="fw-bold">Select Time</span> to proceed.
        </p>
      </div>
      <Calendar />
    </div>
  );
}

function Calendar() {
  // Note: Will become stale if the website is kept open for days on end (Rare but possible)
  const { current: today } = useRef(new Date());
  const { current: nextWeek } = useRef(addDays(today, 7));
  const [loading, setLoading] = useState(false);
  const { validTimeslots: rawTimeslots, reloadValidTimeslots } = useValidTimeslots(false);

    useEffect(() => {
      setLoading(true);
      reloadValidTimeslots();
      setTimeout(() => {
        setLoading(false);
      },  1000);
    }, [reloadValidTimeslots]);
    
  // Convert rawTimeslots into timeslots with Date instances
  // useMemo is used so new Date() objects don't have to recreated over and over again
  const timeslots = useMemo(() => {
    return rawTimeslots.map((timeslot) => {
      return {
        ...timeslot,
        start_time: new Date(timeslot.start_time),
        end_time: new Date(timeslot.end_time),
      };
    });
  }, [rawTimeslots]);

  const [startDate, setStartDate] = useState(today);
  const [selectedTimeslot, setSelectedTimeslot] = useState(null);
  const disableSelectTime = !selectedTimeslot;
  let startOfWeekDate = startOfWeek(today);
  let endOfWeekDate = endOfWeek(today);

  // If it is Saturday, show the next week instead
  if (today.getDay() === 6) {
    startOfWeekDate = startOfWeek(nextWeek);
    endOfWeekDate = endOfWeek(nextWeek);
  }

  const handleSelectTimeslot = (timeslotId, startTime, endTime) => {
    const dayStr = startDate.toLocaleDateString("en-us", {
      weekday: "long",
      month: "long",
      day: "numeric",
    });

    const startTimeStr = startTime.toLocaleTimeString("en-US", {
      timeStyle: "short",
    });
    const endTimeStr = endTime.toLocaleTimeString("en-US", {
      timeStyle: "short",
    });
    const selectedData = {
      id: timeslotId,
      text: `${dayStr} ${startTimeStr} - ${endTimeStr}`,
    };

    // Only meant for carrying over to /select-items/timeslotId.
    // Only used for optimization as a placeholder while accurate data loads so even if it is edited, it is okay
    localStorage.setItem("selectedData", JSON.stringify(selectedData));
    setSelectedTimeslot(selectedData);
  };

  // Only include dates that have timeslots for the next 7 days, can change range as needed
  // Range should be >= range specified in DatePicker element
  const includeArr = useMemo(() => {
    return new Array(8)
      .fill(0)
      .map((_, offset) => {
        return addDays(startOfWeekDate, offset);
        //return addDays(new Date(), offset);
      })
      .filter((currDate) => {
        // If this is a timeslot for today, check if its currently past 8:00 AM. If it is, skip
        if (
          currDate.toDateString() === today.toDateString() &&
          currDate.getHours() >= 8
        ) {
          return false;
        }

        // Include day if there is at least 1 timeslot for that day
        const filteredTimeslots = timeslots.filter((item) => {
          if (!item.display) return false;

          // If this is a timeslot for today, check if its currently past 8:00 AM. If it is, return false
          if (
            item.start_time.getDate() === today.getDate() &&
            today.getHours() >= 8
          ) {
            return false;
          }

          // Filter timeslots for selected day
          return (
            item.start_time.toLocaleDateString() ===
            currDate.toLocaleDateString()
          );
        });
        // No available time slot for selected day
        if (filteredTimeslots.length === 0) {
          return false;
        }

        return filteredTimeslots.some(
          (item) => item.start_time.toDateString() === currDate.toDateString()
        );
      });
  }, [today, timeslots, startOfWeekDate]);

  // TODO: Initial selection should be first available timeslot
  return (
    <div className="row d-flex justify-content-center row__container">
      <div className="col d-flex justify-content-center calendar-col">
        <DatePicker
          selected={startDate}
          minDate={today}
          maxDate={nextWeek}
          includeDates={includeArr}
          onChange={(date) => {
            setStartDate(date);
            setSelectedTimeslot(null);
          }}
          inline={true}
        />
      </div>
      <div className="col d-flex flex-column align-items-center timeslot-col">
        {includeArr.length !== 0 && (
          <>
            <div className="timeslot-col__heading">Timeslots Available On</div>
            <div className="date-string timeslot-col__subheading mb-1 text-center">
              {startDate.toLocaleDateString("en-us", {
                weekday: "long",
                month: "long",
                day: "numeric",
              })}
            </div>
          </>
        )}

      {loading ? (
        <tr>
          <td colSpan={100}>
            <Loading />
          </td>
        </tr>
      ) : 

      // {includeArr.length === 0 ? (
      //   <div className="timeslot-col__heading d-flex flex-column align-items-center">
      //     <div>There are currently no timeslots available!</div>
      //     <div>Please check back soon.</div>
      //   </div>
      // ) : 
      (
          <>
            <Timeslots
              timeslots={timeslots}
              selectedTimeslot={selectedTimeslot}
              handleSelectTimeslot={handleSelectTimeslot}
              startDate={startDate}
            />
            <Link
              className={`btn btn-sm m-1 ${
                disableSelectTime
                  ? "btn-secondary pe-none"
                  : "btn-primary text-white"
              }`}
              to={
                disableSelectTime ? "#" : `/select-items/${selectedTimeslot.id}`
              }
              aria-disabled={disableSelectTime}
              tabIndex={disableSelectTime ? -1 : 0}
            >
              Select Time
            </Link>
          </>
        )}
      </div>
    </div>
  );
}

function Timeslots({
  timeslots,
  selectedTimeslot,
  handleSelectTimeslot,
  startDate,
}) {

  const filteredTimeslots = timeslots
    .filter((item) => {
      // If this is a timeslot for today, check if its currently past 8:00 AM. If it is, return false
      if (
        item.start_time.getDate() === new Date().getDate() &&
        new Date().getHours() >= 8
      ) {
        return false;
      }

      // Filter timeslots for selected day
      return (
        item.start_time.toLocaleDateString() ===
          startDate.toLocaleDateString() && item.display
      );
    })
    .sort((a, b) => {
      const startDiff = a.start_time.getTime() - b.start_time.getTime();

      if (startDiff) return startDiff;
      return a.end_time.getTime() - b.end_time.getTime();
    });

  if (filteredTimeslots.length === 0) {
    return (
      <div className="timeslot-col__heading d-flex flex-column align-items-center">
        <div>No timeslots available for selected day!</div>
      </div>
    );
  }

  return filteredTimeslots.map((item) => {
    const startTime = new Date(item.start_time);
    const endTime = new Date(item.end_time);

    return (
      <button
        key={item.id}
        style={{
          backgroundColor:
            selectedTimeslot && item.id === selectedTimeslot.id
              ? "rgb(206, 145, 155)"
              : "",
          color:
            selectedTimeslot && item.id === selectedTimeslot.id
              ? "#F0F0F0"
              : "",
        }}
        className="btn btn-outline-lightPink btn-sm m-1"
        onClick={() => handleSelectTimeslot(item.id, startTime, endTime)}
      >
        {`${startTime.toLocaleTimeString("en-US", {
          timeStyle: "short",
        })} - ${endTime.toLocaleTimeString("en-US", { timeStyle: "short" })}`}
      </button>
    );
  });
}
