import axios from "axios";
import Cookies from "js-cookie";
import AuthService from "./services/auth.service";

export const SERVER_URL =
  process.env.REACT_APP_SERVER_URL || "http://localhost:8000";
export const CLIENT_URL =
  process.env.REACT_APP_CLIENT_URL || "http://localhost:3000";
// Note: I added a feature where you can choose where to redirect to after auth by concatenating
//       `&state=${encodeURIComponent(`continue=${redirectRoute}`)}`
//       to the end of the AUTH_URL where redirectRoute is the path on this site to redirect to
export const AUTH_URL = `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${SERVER_URL}/api/accounts/google/login/callback/&client_id=751128729620-vc8q2qr2l83muvi2ci8jiqe0804352sq.apps.googleusercontent.com&scope=openid%20email%20profile&access_type=offline&response_type=code&hd=ucdavis.edu`;

export const ORDER_STATUS_CHOICES = [
  { display: "Placed", code: "PL" },
  { display: "Cancelled", code: "CD" },
  { display: "Picked-up", code: "PU" },
  { display: "No-show", code: "NS" },
];

export const CATEGORIES = [
  { handle: "Vegetarian", value: "VT", count: 0, set: false },
  { handle: "Vegan", value: "VE", count: 0, set: false },
  { handle: "Kosher", value: "KO", count: 0, set: false },
  { handle: "Halal", value: "HA", count: 0, set: false },
  { handle: "Locally-Sourced", value: "LO", count: 0, set: false },
];

export const SHORT_CATEGORIES = [
  { handle: "V", value: "VT" },
  { handle: "V+", value: "VE" },
  { handle: "K", value: "KO" },
  { handle: "H", value: "HA" },
  { handle: "L", value: "LO" },
];

export const ALLERGENS = [
  { handle: "Peanuts", value: "PE", count: 0, set: false },
  { handle: "Tree Nuts", value: "TN", count: 0, set: false },
  { handle: "Egg", value: "EG", count: 0, set: false },
  { handle: "Wheat", value: "WH", count: 0, set: false },
  { handle: "Sesame", value: "SE", count: 0, set: false },
  { handle: "Milk", value: "ML", count: 0, set: false },
  { handle: "Soy", value: "SO", count: 0, set: false },
  { handle: "Shellfish", value: "SH", count: 0, set: false },
  { handle: "Fish", value: "FS", count: 0, set: false },
];

export const ALLERGEN_NAMES = {
  NO: "No Major Allergen",
  PE: "Peanuts",
  CH: "Cashews",
  TN: "Other Tree Nuts",
  EG: "Egg",
  DA: "Dairy",
  ML: "Milk",
  SO: "Soy",
  SH: "Shellfish",
  SE: "Sesame",
  SF: "Seafood",
  FS: "Fish",
  WH: "Wheat",
  UN: "Unknown",
};

export const EXTENDED_ALLERGENS = [
  ...ALLERGENS,
  { handle: "Unknown", value: "UN" },
  { handle: "None", value: "NO" },
];

export const STORAGE_TYPES = [
  { handle: "Shelf", value: "SF" },
  { handle: "Fridge", value: "FD" },
  { handle: "Freezer", value: "FZ" },
];

/* Global config for axios */
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";
export const instance = axios.create({
  baseURL: SERVER_URL,
});

/* Intercept axios requests before they are sent and add Auth header */
instance.interceptors.request.use(
  (config) => {
    const token = AuthService.currentUser.data?.accessToken;
    if (token) {
      config.headers["Authorization"] = "Token " + token;
    }
    // console.log(`CSRF: ${Cookies.get("csrftoken")}`)
    if (Cookies.get("csrftoken")) {
      config.headers["X-CSRFToken"] = Cookies.get("csrftoken");
    }
    return config;
  },
  (error) => Promise.reject(error)
);
