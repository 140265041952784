import React, { useState } from "react";
import { useNavigate } from "react-router";
import StaffService from "../../services/staff.service";
import DatePicker from "react-datepicker";
import { Calendar } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { setHours, setMinutes } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import "./timeslot-form.css";

/**
 * @description Boiler plate code for adding/editing timeslots
 * @param {*} Fields
 * @returns JSX.Element
 */
export function TimeslotForm({
  date,
  setDate,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  capacity,
  setCapacity,
  display,
  setDisplay,
  timeslotID,
}) {
  const [sending, isSending] = useState(false);

  return (
    <>
      <h2 className="text-center">Edit Timeslot</h2>
      <div className="form-inputs form-group">
        <div className="form-group staff_calendar">
          <label>Date</label>
          <DatePicker
            id="staff_calendar"
            selected={date}
            onChange={(date) => setDate(date)}
            minDate={new Date()}
          />
        </div>

        <div className="form-group staff_timepicker">
          <label>Start Time</label>
          <DatePicker
            selected={startTime}
            onChange={(time) => setStartTime(time)}
            showTimeSelect
            showTimeSelectOnly
            minTime={setHours(setMinutes(new Date(), 30), 9)}
            maxTime={setHours(setMinutes(new Date(), 60), 16)}
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="h:mm aa"
          />
        </div>

        <div className="form-group staff_timepicker">
          <label>End Time</label>
          <DatePicker
            selected={endTime}
            onChange={(time) => setEndTime(time)}
            showTimeSelect
            showTimeSelectOnly
            minTime={setHours(setMinutes(new Date(), 30), 9)}
            maxTime={setHours(setMinutes(new Date(), 60), 16)}
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="h:mm aa"
          />
        </div>

        <div className="form-group">
          <label>Capacity</label>
          <input
            type="number"
            min="0"
            placeholder="Enter capacity"
            className="form-control"
            value={capacity}
            onChange={(e) => {
              setCapacity(e.target.value);
            }}
          />
        </div>

        <label className="form-group form-check mt-2 gap-1">
          <input
            required
            type="checkbox"
            name="display"
            className="form-check-input"
            checked={display}
            onChange={(e) => {
              setDisplay(e.target.checked);
            }}
          />
          <span>Display</span>
        </label>

        <button
          className="btn btn-primary mt-5"
          onClick={() => {
            startTime.setSeconds(0);
            startTime.setMilliseconds(0);
            endTime.setSeconds(0);
            endTime.setMilliseconds(0);
            let UTC_start = new Date(
              date
                .toLocaleDateString()
                .concat(" " + startTime.toLocaleTimeString())
            ).toISOString();
            let UTC_end = new Date(
              date
                .toLocaleDateString()
                .concat(" " + endTime.toLocaleTimeString())
            ).toISOString();
            const data = {
              startTime: UTC_start,
              endTime: UTC_end,
              capacity: capacity,
              display: display,
            };
            isSending(true);
            StaffService.editTimeslot(data, timeslotID)
              .then((response) => {
                if (response.status === 200) {
                  // Success
                  isSending(false);
                  // navigate("/staff/timeslot");
                  window.location.pathname = "/staff/timeslot";
                } else if (response.status === 400) {
                  // Client error
                  isSending(false);
                  alert(response.data);
                } else if (response.status === 500) {
                  // Server error
                  isSending(false);
                  alert(response.statusText);
                }
              })
              .catch((err) => {
                console.err(err);
                isSending(false);
              });
          }}
        >
          Edit Timeslot{" "}
          {sending && (
            <span className="spinner-border spinner-border-sm"></span>
          )}
        </button>
      </div>
    </>
  );
}

export function AddTimeslotForm({
  date,
  setDate,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  capacity,
  setCapacity,
  display,
  setDisplay,
}) {
  const [timeArr, setTimeArr] = useState([]);
  const [sending, isSending] = useState(false);
  const navigate = useNavigate();

  const pushTimeArr = (data) => {
    setTimeArr((arr) => [...arr, data]);
  };

  const delTimeslot = (index) => {
    const arr = [...timeArr];
    arr.splice(index, 1);
    setTimeArr(arr);
  };

  /* Render for Adding timeslots */
  return (
    <>
      <h2 className="text-center">Add Timeslots</h2>
      <p className="text-center">
        Select desired date(s) to add timeslots for. Then add timeslots by
        picking a start and end time. Finally, click "Create Timeslots" once
        finished.
      </p>
      <div className="form-inputs form-group flex-row justify-content-around">
        <div className="form-group d-flex flex-column">
          <Calendar
            multiple
            sort
            value={date}
            onChange={(dateobject) => {
              setDate(dateobject);
            }}
            plugins={[<DatePanel position="bottom" />]}
          />
        </div>

        <div className="form-group d-flex flex-column">
          <label className="form-group staff_timepicker">
            <span>Start Time</span>
            <DatePicker
              selected={startTime}
              onChange={setStartTime}
              showTimeSelect
              showTimeSelectOnly
              placeholderText="Click to select a time"
              isClearable={true}
              minTime={setHours(setMinutes(new Date(), 30), 9)}
              maxTime={setHours(setMinutes(new Date(), 60), 16)}
              timeIntervals={30}
              timeCaption="Time"
              dateFormat="h:mm aa"
            />
          </label>

          <label className="form-group staff_timepicker">
            <span>End Time</span>
            <DatePicker
              selected={endTime}
              onChange={setEndTime}
              showTimeSelect
              showTimeSelectOnly
              isClearable={true}
              placeholderText="Click to select a time"
              minTime={setHours(setMinutes(new Date(), 30), 9)}
              maxTime={setHours(setMinutes(new Date(), 60), 16)}
              timeIntervals={30}
              timeCaption="Time"
              dateFormat="h:mm aa"
            />
          </label>

          <label className="form-group">
            <span>Capacity</span>
            <input
              type="number"
              min="0"
              placeholder="Enter capacity"
              className="form-control"
              value={capacity}
              onChange={(e) => {
                setCapacity(e.target.value);
              }}
            />
          </label>

          <label className="form-group form-check mt-2 gap-1">
            <input
              required
              type="checkbox"
              name="display"
              className="form-check-input"
              checked={display}
              onChange={(e) => {
                setDisplay(e.target.checked);
              }}
            />
            <span>Display</span>
          </label>
          <button
            className="btn btn-primary mt-2"
            onClick={() => {
              /* Don't care about seconds */
              startTime.setSeconds(0);
              startTime.setMilliseconds(0);
              endTime.setSeconds(0);
              endTime.setMilliseconds(0);

              /* Build post request data */
              const data = {
                startTime: startTime,
                endTime: endTime,
                capacity: capacity,
                display: display,
              };

              /* Only create this timeslot if it doesn't exist */
              if (
                !timeArr.find(
                  (timeslot) =>
                    timeslot.startTime === data.startTime &&
                    timeslot.endTime === data.endTime
                )
              ) {
                pushTimeArr(data);
              } else {
                alert("Timeslot already exists!");
              }
            }}
          >
            Add Timeslot
          </button>
        </div>

        <div className="form-group d-flex flex-column">
          {timeArr.length === 0 ? (
            <p>No Timeslots Added.</p>
          ) : (
            <div className="container">
              <p className="text-center">Timeslots</p>
              {timeArr.map((item, index) => {
                return (
                  <div
                    className="d-flex justify-content-between align-items-center"
                    key={index}
                  >
                    <button
                      type="button"
                      className="btn-close"
                      aria-label="Close"
                      onClick={() => delTimeslot(index)}
                    ></button>
                    <span className="border border-2 rounded p-1">
                      {`${new Date(item.startTime).toLocaleTimeString("en-US", {
                        hour: "2-digit",
                        minute: "2-digit",
                      })} - ${new Date(item.endTime).toLocaleTimeString(
                        "en-US",
                        {
                          hour: "2-digit",
                          minute: "2-digit",
                        }
                      )} Capacity: ${item.capacity}`}
                    </span>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      <div className="container d-flex justify-content-center">
        <button
          className="btn btn-success"
          disabled={date.length === 0 || timeArr.length === 0 || sending}
          onClick={() => {
            /* Hold post request data */
            const postArr = [];

            /* Construct post request data */
            for (let i = 0; i < date.length; i++) {
              for (let j = 0; j < timeArr.length; j++) {
                let UTC_start = new Date(
                  date[i]
                    .format("MM/DD/YYYY")
                    .concat(" " + timeArr[j].startTime.toLocaleTimeString())
                ).toISOString();
                let UTC_end = new Date(
                  date[i]
                    .format("MM/DD/YYYY")
                    .concat(" " + timeArr[j].endTime.toLocaleTimeString())
                ).toISOString();

                const data = {
                  startTime: UTC_start,
                  endTime: UTC_end,
                  capacity: timeArr[j].capacity,
                  display: timeArr[j].display,
                };

                postArr.push(data);
              }
            }

            /* Send post request data */
            isSending(true);
            let addTimeslots = Promise.all(
              postArr.map((item) => {
                return StaffService.addTimeslot(item);
              })
            );

            addTimeslots
              .then((response) => {
                if (response.some((res) => res.status === 400)) {
                  // Client error
                  alert(
                    response[response.findIndex((res) => res.status === 400)]
                      .data
                  );
                } else if (response.some((res) => res.status === 500)) {
                  // Server error
                  alert(
                    response[response.findIndex((res) => res.status === 500)]
                      .statusText
                  );
                } else if (response.every((res) => res.status === 200)) {
                  // Success
                  navigate("/staff/timeslot");
                }

                isSending(false);
              })
              .catch((err) => {
                console.err(err);
                isSending(false);
              });
          }}
        >
          Create Timeslots{" "}
          {sending && (
            <span className="spinner-border spinner-border-sm"></span>
          )}
        </button>
        <button
          className="ml-3 btn btn-danger"
          onClick={() => window.history.back()}
        >
          Go Back
        </button>
      </div>
    </>
  );
}
