import { useEffect, useState } from "react";
import AuthService from "../services/auth.service";

/**
 * Allows read-only access to the user information
 * Automatically re-renders components using this hook when user updates occur
 * @return {CurrentUser}
 */
export default function useAuth() {
  const [user, setUser] = useState(AuthService.currentUser);
  useEffect(() => {
    const listener = (e) => {
      setUser(e.detail);
    };
    AuthService.updates.addEventListener("change", listener);
    return () => AuthService.updates.removeEventListener("change", listener);
  }, []);

  return user;
}

/**
 * Hook provided for convenience
 * Only returns the highest read-only role of the user or an empty string
 * @return {string}
 */
export function useRole() {
  const { data: user } = useAuth();

  if (!user) return "";
  return user.role || "";
}
