import { useEffect, useState } from "react";
import { useCart } from "react-use-cart";
import { MdOutlineFiberNew } from "react-icons/md";
import { HiChevronUp, HiChevronDown, HiSelector } from "react-icons/hi";
import dateParser from "../../../helpers/date-parser";
import ItemProductModal from "../../units/product-modal";
import useIncrementDecrement from "./useIncrementDecrement";
import useAuth from "../../../helpers/useAuth";
import "./order.css";
import "./order-table.css";
const MAX_ITEM_COUNT = 40;

const sortOptions = {
  name: "name",
  limit: "limit",
  amount: "amount",
  none: "",
};

/**
 * Constructs the order table containing information for each product item.
 * Users are able to select, add, or delete items from their cart by interacting
 * with each product.
 *
 * Users are able to click on the info icon to read more information about the product.
 * Users are also able to sort the items displayed on the table by name or limit (or amount instead if the user is a staff)
 *
 * Developer note: Only provide items that should be displayed in the filteredDisplayInventory prop. Filter before providing the inventory to this component
 * @returns JSX.Element
 */
export function OrderTable({
  filteredDisplayInventory,
  page,
  maxPerPage,
  loading,
}) {
  // Version of the inventory that will be kept sorted at all times
  const [sortedInventory, setSortedInventory] = useState([]);

  // Should be flipped to true each time the sort column is changed
  // Can only be false if the column it is currently sorting by is clicked while true
  const [sortAscending, setSortAscending] = useState(true);
  // Item property to sort by. Will be sorted by value if a number and alphabetically otherwise. Empty string for no sort
  const [activeSortKey, setActiveSortKey] = useState(sortOptions.name);

  // Sorts the inventory every time a sort changes
  useEffect(() => {
    const newSortedInventory = filteredDisplayInventory.slice().sort((a, b) => {
      if (activeSortKey === sortOptions.none) return false;
      // Sorting in reverse is the same as flipping the sign
      const ascendingFlipFactor = sortAscending ? 1 : -1;

      if (activeSortKey === sortOptions.name) {
        // sort by name, default is ascending
        return ascendingFlipFactor * a.name.localeCompare(b.name);
      }

      if (typeof a[activeSortKey] === "number") {
        return ascendingFlipFactor * (a[activeSortKey] - b[activeSortKey]);
      }
      return (
        ascendingFlipFactor * (a[activeSortKey] > b[activeSortKey] ? 1 : -1)
      );
    });

    setSortedInventory(newSortedInventory);
  }, [filteredDisplayInventory, sortAscending, activeSortKey]);

  // Changes the sort column. If the column is unchanged, the sortAscending will be inverted instead
  const toggleSortBy = (newSortKey) => {
    if (newSortKey === activeSortKey) {
      setSortAscending(!sortAscending);
      return;
    }

    // Change sort column
    setActiveSortKey(newSortKey);
    if (!sortAscending) setSortAscending(true);
  };

  return (
    <section className="table-container w-100 mx-0 px-lg-4">
      <table className="table caption-top table-hover">
        <OrderTableHead
          activeSortKey={activeSortKey}
          sortAscending={sortAscending}
          toggleSortBy={toggleSortBy}
        />
        <tbody>
          {((!filteredDisplayInventory && !loading) ||
            (!filteredDisplayInventory.length && !loading)) && (
            <tr>
              <td colSpan={100}>
                <b>There are currently no items to view.</b>
              </td>
            </tr>
          )}

          {sortedInventory
            .slice((page - 1) * maxPerPage, page * maxPerPage)
            .map((item) => (
              <OrderTableItemRow key={item.id} item={item} />
            ))}
        </tbody>
      </table>
    </section>
  );
}

function OrderTableHead({ sortAscending, activeSortKey, toggleSortBy }) {
  const { data: user } = useAuth();
  const isStaff = user ? user.role === "Staff" : false;

  return (
    <thead>
      <tr>
        <th scope="col">Product</th>
        <th scope="col">
          <button
            title="Sort By Name"
            className="sort-button"
            onClick={() => toggleSortBy(sortOptions.name)}
          >
            <b>Item Name</b>
            {activeSortKey === sortOptions.name ? (
              !sortAscending ? (
                <HiChevronDown />
              ) : (
                <HiChevronUp />
              )
            ) : (
              <HiSelector />
            )}
          </button>
        </th>
        <th scope="col">
          <button
            title={`Sort By "Limit"`}
            className="sort-button px-1"
            onClick={() =>
              toggleSortBy(sortOptions.limit)
            }
          >
            <b>{"Limit"}</b>
            {activeSortKey ===
            (sortOptions.limit) ? (
              !sortAscending ? (
                <HiChevronDown />
              ) : (
                <HiChevronUp />
              )
            ) : (
              <HiSelector />
            )}
          </button>
        </th>
        <th className="text-center px-1" scope="col">
          Quantity
        </th>
      </tr>
    </thead>
  );
}

function OrderTableItemRow({ item }) {
  const { getItem, totalItems } = useCart();
  const quantityInCart = getItem(item.id)?.itemTotal || 0;
  const { handleIncrement, handleDecrement } = useIncrementDecrement();
  const { data: user } = useAuth();
  const isStaff = user ? user.role === "Staff" : false;
  const recentlyAdded = dateParser(item.date_added);

  return (
    <tr className="table-row position-relative">
      <td className="d-none d-md-table-cell product">
        <ItemProductModal item={item} />
      </td>

      <td
        className={`d-none d-md-table-cell ${
          recentlyAdded ? "text-success" : ""
        }`}
      >
        {recentlyAdded && <MdOutlineFiberNew size={20} />} {item.name}
      </td>

      {/* Mobile product modal and name */}
      <td className="d-table-cell d-md-none" colSpan={2}>
        <ItemProductModal item={item} />
      </td>

      <td className="w-0 px-1 text-center">
        {item.limit - quantityInCart}
      </td>

      <td
        className="w-0 px-2 px-md-4 text-nowrap text-center quantity-controls"
      >
        <button
          className={`btn mx-auto d-block d-sm-inline-block ${
            quantityInCart === 0 ? "btn-secondary disabled" : "btn-danger"
          } quantity-decrement`}
          onClick={() => {
            handleDecrement(item);
          }}
        >
          -
        </button>
        <span className="d-inline-block px-1 cart-quantity">
          {quantityInCart}
        </span>
        <button
          className={`btn mx-auto d-block d-sm-inline-block ${
              quantityInCart < item.limit && totalItems < MAX_ITEM_COUNT
              ? "btn-primary"
              : "btn-secondary disabled"
          } quantity-increment`}
          onClick={() => {
            if (totalItems < MAX_ITEM_COUNT) handleIncrement(item);
          }}
        >
          +
        </button>
      </td>
    </tr>
  );
}
