export default function EmailContainer(props) {
  const {
    children,
    className = "",
    labelText = "Email: ",
    buttonText = "Confirm",
    email = "",
    inputTitle = "Please use your UC Davis email!",
    setInput,
    onConfirm,
    disabled = false,
    ...extraProps
  } = props;

  return (
    <div className={`email-container w-100 gap-1 ${className}`} {...extraProps}>
      <div className="d-flex flex-column align-items-middle">
        <div className="d-flex flex-column gap-2 justify-content-center align-items-center flex-sm-row gap-sm-0 ">
          <label
            className={`d-flex align-items-center mt-0 ${
              labelText.length > 8 ? "flex-column flex-sm-row" : "flex-row"
            }`}
          >
            <span>{labelText}</span>
            <input
              className="w-100 ms-2 me-2"
              type="email"
              placeholder="example@ucdavis.edu"
              title={inputTitle}
              value={email}
              onChange={(e) => setInput(e.target.value.trim().toLowerCase())}
              disabled={disabled}
            />
          </label>
          <button
            className="btn btn-success btn-sm"
            onClick={onConfirm}
            disabled={disabled}
          >
            {buttonText}
          </button>
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
}
