import { useEffect, useState } from "react";
import { HiChevronUp, HiChevronDown, HiSelector } from "react-icons/hi";
import DatePicker from "react-datepicker";
import PublicApiService from "../../../../services/public-api.service";
import StaffService from "../../../../services/staff.service";
import "react-datepicker/dist/react-datepicker.css";
import "./fulfill-orders.css";

async function getOrders() {
  // Batch fetch API data
  const [orderArr, timeslotArr] = await Promise.all([
    StaffService.fetchOrders(),
    PublicApiService.getTimeslots(),
  ]);

  // Convert timeslot's id to JS Date object then remove orders that aren't placed or picked up
  return orderArr
    .map((order) => {
      return {
        ...order,
        time_slot: timeslotArr.find((ts) => ts.id === order.time_slot),
      };
    })
    .filter((order) => order.status === "PL" || order.status === "PU");
}

export default function FulfillOrders() {
  const sortOptions = {
    id: "id",
    time: "time",
    fulfilled: "fulfilled",
  };

  const [orders, setOrders] = useState([]);
  const [fetchState, setFetchState] = useState(false);

  // Filters
  const [dateFilter, setDateFilter] = useState(new Date());
  const [idFilter, setIdFilter] = useState("");

  // Sorts: Defaults to sort by id
  const [activeSort, setActiveSort] = useState(sortOptions.id);
  const [sortAscending, setSortAscending] = useState(true);

  const toggleSort = (option) => {
    if (activeSort === option) {
      setSortAscending(!sortAscending);
      return;
    }
    setActiveSort(option);
    setSortAscending(true);
  };

  // On component mount
  useEffect(() => {
    getOrders().then((res) => setOrders(res));
  }, [fetchState]);

  // Filter by dateFilter and search input then sort appropriately
  const filteredOrders = orders
    .filter(
      (order) =>
        new Date(order.time_slot.start_time).toDateString() ===
        dateFilter.toDateString()
    )
    .filter((order) => !idFilter || order.id.toString().includes(idFilter))
    .sort((a, b) => {
      if (activeSort === sortOptions.time) {
        const timeA = new Date(b.time_slot.start_time);
        const timeB = new Date(b.time_slot.start_time);
        return sortAscending
          ? timeA > timeB
            ? 1
            : -1
          : timeB > timeA
          ? 1
          : -1;
      }
      // Default to sort by id
      return (sortAscending ? 1 : -1) * (a.id - b.id);
    });

  return (
    <div className="mx-0">
      <h1 className="text-center" id="top-of-page">
        Orders
      </h1>
      <div className="d-flex justify-content-between">
        <span className="d-flex gap-2">
          {/* removed the button */}
          {/* <div className="items-on-hold">
            <span>{`Items on Hold ${
              dateFilter.getMonth() + 1
            }/${dateFilter.getDate()}: ${filteredOrders.length}`}</span>
          </div> */}
          <DatePicker
            selected={dateFilter}
            onChange={(date) => {
              setDateFilter(date);
            }}
          />
        </span>
        <span>
          <div className="input-group">
            <input
              className="form-control"
              id="search-input"
              value={idFilter}
              placeholder="Search by ID"
              onChange={(e) => {
                setIdFilter(e.target.value);
              }}
              autoComplete="off"
            />
            <button
              className="btn btn-outline-danger"
              type="button"
              onClick={() => {
                // Clear input field
                document.getElementById("search-input").value = "";
                setIdFilter("");
              }}
            >
              Clear
            </button>
          </div>
        </span>
      </div>

      <table className="w-100 table px-0 mx-0 mt-1 order-fulfill-table">
        <thead className="order-fulfill-table-header align-middle bold">
          <tr>
            <td className="text-center order-fulfill-table-column-fit p-0">
              <button
                className="w-100 h-100 px-3 py-2 sort-button"
                onClick={() => toggleSort(sortOptions.id)}
              >
                <span className="fw-bold">ID</span>
                {activeSort === sortOptions.id ? (
                  !sortAscending ? (
                    <HiChevronDown />
                  ) : (
                    <HiChevronUp />
                  )
                ) : (
                  <HiSelector />
                )}
              </button>
            </td>
            {/* <td className="text-center order-fulfill-table-column-fit p-0">
              <button
                className="w-100 h-100 sort-button"
                onClick={() => toggleSort(sortOptions.time)}
              >
                <span className="fw-bold">Time</span>
                {activeSort === sortOptions.time ? (
                  !sortAscending ? (
                    <HiChevronDown/>
                  ) : (
                    <HiChevronUp/>
                  )
                ) : (
                  <HiSelector/>
                )}
              </button>
            </td> */}
            <td>Contents</td>
            {/* <th scope="col" className="align-right">Operation</th> */}
            <td className="text-center order-fulfill-table-column-fit order-fulfill-table-fulfill-label">
              {/* Label made in CSS */}
            </td>
          </tr>
        </thead>
        <tbody>
          {filteredOrders.length === 0 && !idFilter && (
            <tr>
              <td colSpan={4}>
                <p className="text-center my-1">
                  No Orders for Date [{dateFilter.toLocaleDateString()}]
                </p>
              </td>
            </tr>
          )}

          {idFilter && filteredOrders.length === 0 && (
            <tr>
              <td colSpan={4}>
                <p className="text-center my-1">
                  No matches for ID [{idFilter}]
                </p>
              </td>
            </tr>
          )}

          {filteredOrders.map((order) => (
            <OrderEntry
              key={order.id}
              state={fetchState}
              onStateChange={setFetchState}
              {...order}
            />
          ))}
        </tbody>
      </table>
      <a href="#top-of-page" style={{ textDecoration: "none" }}>
        <b>Jump to top</b>
      </a>
    </div>
  );
}

function OrderEntry(props) {
  const {
    state,
    onStateChange,
    id,
    order_products,
    status,
    // time_slot,
  } = props;
  const [saving, setSaving] = useState(false);
  const fulfilled = status === "PU";
  // let navigate = useNavigate();

  // Change order status from 'PU' <--> 'PL'
  const changeStatus = async () => {
    const orderInformation = {
      status: status === "PL" ? "PU" : "PL",
    };

    setSaving(true);
    await StaffService.editOrderStatus(orderInformation, id);
    onStateChange(!state); // Force re-render on parent and its children
    setSaving(false);
  };

  // // Timeslot string
  // const timeStart = new Date(time_slot.start_time).toLocaleTimeString("en-US", {
  //   hour: "2-digit",
  //   minute: "2-digit",
  // });
  // const timeEnd = new Date(time_slot.end_time).toLocaleTimeString("en-US", {
  //   hour: "2-digit",
  //   minute: "2-digit",
  // });

  return (
    <tr className={`${fulfilled ? "order-fulfilled-row" : ""}`}>
      <td className="text-center">{id}</td>
      {/* <td className="text-center order-fulfill-entry-time">
        <span className="text-nowrap">{timeStart}</span>-
        <span className="text-nowrap">{timeEnd}</span>
      </td> */}
      <td>
        {order_products.length === 0 && <p className="py-0 mb-1">No items</p>}
        {order_products.map((item) => (
          <p key={item.item} className="py-0 mb-1">
            {`${item.quantity} ${item.item_name}`}
          </p>
        ))}
      </td>
      {/* <td className="align-right">
        <button
          className="btn btn-warning"
          onClick={() => {
            navigate(`/staff/volunteer-edit-order/${id}`);
          }}
        >
          Edit
        </button>
      </td> */}
      <td className="p-0">
        <label className="w-100 h-100 m-0 p-3 flex-grow-1 order-fulfill-table-entry-fulfilled-label">
          {saving ? (
            <div
              className="spinner-border border-color-success"
              role="status"
              aria-label="Saving..."
            />
          ) : (
            <input
              type="checkbox"
              checked={fulfilled}
              onChange={changeStatus}
            />
          )}
        </label>
      </td>
    </tr>
  );
}
