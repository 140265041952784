import Modal from "react-modal";
import { useState } from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import Legend from "../../resources/system-icons/Icons_Key Poster_AY_R2-02.png";

const itemModalStyle = {
  content: {
    top: "50%", // Center the modal vertically
    left: "50%", // Center the modal horizontally
    transform: "translate(-50%, -50%)",
  },
};

/**
 * @description Construct image modal for allergen key on request
 * @returns JSX.Element
 */

export default function AllergenModal({ modalProps }) {
  const [legendOpen, onLegendOpen] = useState(false);

  function openLegendModal() {
    onLegendOpen(true);
  }

  function closeLegendModal() {
    onLegendOpen(false);
  }

  return (
    <>
      <p className="allergen-key-i" onClick={openLegendModal}>
        <AiOutlineQuestionCircle /> Allergen Key
      </p>

      <Modal
        id='allergen-modal-order'
        isOpen={legendOpen}
        onRequestClose={closeLegendModal}
        style={itemModalStyle}
        contentLabel="Allergen Legend"
        {...modalProps}
      >
        {" "}
        <div className="modal-header">
          <p>{""}</p>
          <button className="btn btn-danger" onClick={closeLegendModal}>
            X
          </button>
        </div>
        <div className="center">
          <img id="legend-img" src={Legend} alt="Legend" />
        </div>
      </Modal>
    </>
  );
}