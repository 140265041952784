import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import InventoryModal from "../edit-order/inventory-modal";
import UserService from "../../../../services/user.service";
import useAuth from "../../../../helpers/useAuth";
import { ORDER_STATUS_CHOICES } from "../../../../constants";
import { useValidTimeslots } from "../../../../helpers/publicAPIHooks";

export default function AddOrder() {
  const { data: user } = useAuth();
  const [email, setEmail] = useState(user?.email || "");
  useEffect(() => setEmail(user?.email || ""), [user?.email]);
  const navigate = useNavigate();

  // Use valid timeslots
  const { validTimeslots } = useValidTimeslots();
  const [displayedTimeslots, setDisplayedTimeslots] = useState([]);
  const [selectedTimeslot, setSelectedTimeslot] = useState(0);
  const [orderProducts, setOrderProducts] = useState([]);
  const [status, setStatus] = useState("");
  const [showingTable, setShowingTable] = useState(false);
  const [inventory, setInventory] = useState();
  const [sending, isSending] = useState(false);

  useEffect(() => {
    const newDisplayedTimeslots = validTimeslots
      .filter((slot) => slot.display)
      .sort((a, b) => {
        const bStart = new Date(b.start_time).getTime();
        const aStart = new Date(a.start_time).getTime();
        if (aStart !== bStart) return bStart - aStart;

        const bEnd = new Date(b.end_time).getTime();
        const aEnd = new Date(a.end_time).getTime();
        return bEnd - aEnd;
      });

    setDisplayedTimeslots(newDisplayedTimeslots);
    if (newDisplayedTimeslots.length)
      setSelectedTimeslot(newDisplayedTimeslots[0].id);
  }, [validTimeslots]);

  useEffect(() => {
    UserService.getPatronBoard()
      .then((res) => setInventory(res.data))
      .catch((err) => console.log(err));
  }, []);

  function deleteOrderProduct(idx) {
    setOrderProducts(
      orderProducts.filter((product, innerIdx) => {
        if (innerIdx === idx) {
          product.item_name = "X";
          product.quantity = 0;
        }
        if (idx === 0) {
          return orderProducts.slice(1, orderProducts.length);
        }
        return idx;
      })
    );
  }

  function modifyItemQuantity(idx, e) {
    setOrderProducts(
      orderProducts.map((order, innerIdx) => {
        if (innerIdx === idx) {
          order.quantity = Number(e.target.value);
          if (isNaN(order.quantity)) {
            order.quantity = 0;
          }
        }

        return order;
      })
    );
  }

  function modifyItemName(idx, e) {
    setOrderProducts(
      orderProducts.map((order, innerIdx) => {
        if (innerIdx === idx) {
          order.item_name = e.target.value;
        }
        return order;
      })
    );
  }

  function onAddNew() {
    setShowingTable(true);
  }

  function addNewItem(item) {
    setOrderProducts((prev) => [...prev, item]);
  }

  // return to previous page
  const goBack = () => {
    window.history.back();
  };

  return (
    <>
      {showingTable ? (
        <InventoryModal
          setShowingTable={setShowingTable}
          showingTable={showingTable}
          addNewItem={addNewItem}
          orderProducts={orderProducts}
          inventory={inventory}
        />
      ) : (
        <></>
      )}

      <h2 className="text-center">Create order</h2>

      <div className="form-inputs form-group">
        <div className="form-group">
          <label>Email</label>
          <input
            type="text"
            placeholder="Required"
            className="form-control input-lg"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          ></input>
        </div>
      </div>

      <div className="order-drop-down-group">
        <div className="form-group">
          <label>Timeslot</label>
          <select
            className="form-select form-order"
            value={selectedTimeslot}
            onChange={(e) => {
              setSelectedTimeslot(e.target.value);
            }}
          >
            {displayedTimeslots.map((ts, idx) => {
              return (
                <option value={ts.id} key={idx}>
                  {`${new Date(ts.start_time).toLocaleDateString()} ${new Date(
                    ts.start_time
                  ).toLocaleTimeString("en-US", {
                    timeStyle: "short",
                  })} - ${new Date(ts.end_time).toLocaleTimeString("en-US", {
                    timeStyle: "short",
                  })}`}
                </option>
              );
            })}
          </select>
        </div>
      </div>

      <div className="order-drop-down-group">
        <div className="form-group">
          <label>Status</label>
          <select
            className="form-select form-order"
            value={status}
            onChange={(e) => {
              setStatus(e.target.value);
            }}
          >
            {ORDER_STATUS_CHOICES.map((status, idx) => (
              <option value={status.code} key={idx}>
                {status.display}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="order-outer-container">
        <table className="table caption-top order-table-container table-responsive">
          <caption>Order Content</caption>
          <thead>
            <tr>
              <th scope="col">Item</th>
              <th scope="col">Quantity</th>
              <th scope="col">Remove</th>
            </tr>
          </thead>
          <tbody>
            {orderProducts.map((product, idx) => {
              return (
                <tr key={idx}>
                  <td>
                    <input
                      value={product.item_name}
                      onChange={(e) => {
                        modifyItemName(idx, e);
                      }}
                    />
                  </td>
                  <td>
                    <input
                      value={product.quantity}
                      onChange={(e) => {
                        modifyItemQuantity(idx, e);
                      }}
                    />
                  </td>
                  <td>
                    <button
                      className="btn btn-danger"
                      onClick={() => {
                        deleteOrderProduct(idx);
                      }}
                    >
                      X
                    </button>
                  </td>
                </tr>
              );
            })}
            <tr key="new">
              <td>
                <div>
                  <button
                    className="btn btn-success"
                    onClick={() => {
                      onAddNew();
                    }}
                  >
                    Add New Item
                  </button>
                </div>
              </td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <button
          className="mt-5 btn btn-success"
          onClick={() => {
            isSending(true);
            UserService.createOrder(email, selectedTimeslot, orderProducts)
              .then((res) => {
                if (res.status === 200) {
                  // Success
                  navigate("/staff/order/");
                } else if (res.status === 400) {
                  // Client error
                  alert(res.data['header']);
                } else if (res.status === 500) {
                  // Server error
                  alert(res.statusText);
                }

                isSending(false);
              })
              .catch((err) => {
                console.err(err);
                isSending(false);
              });
          }}
        >
          Create Order{" "}
          {sending && (
            <span className="spinner-border spinner-border-sm"></span>
          )}
        </button>
        <button className="mt-2 btn btn-danger" onClick={goBack}>
          Cancel
        </button>
      </div>
    </>
  );
}
