import { instance } from "../constants";
import AuthService from "./auth.service";

class UserService {
  getPatronBoard() {
    return instance.get("/api/items/").catch(console.error);
  }

  /**
   * @description Create a new order
   * @arg email String of user email
   * @arg time_slot Timeslot ID
   * @arg orderproducts JSON object of item's ID and quantity
   * @arg orderproducts Example format
   * [
   *   {
   *       "item": 6,
   *       "quantity": 5,
   *   },
   *   {
   *       "item": 3,
   *       "quantity": 1,
   *   }
   * ]
   * @returns String of user's role
   */
  createOrder(email, time_slot, orderproducts) {
    return instance
      .post("/api/create-order/", {
        email,
        time_slot,
        orderproducts,
      })
      .catch((error) => error.response);
  }

  /**
   * @description User level endpoint for viewing Orders
   * @GET request
   * @returns array of Orders in JSON format
   */
  viewOrders() {
    return instance
      .get("/api/view-orders/")
      .then((response) => response.data)
      .catch(console.error);
  }

  /**
   * @description User level endpoint for previewing an order about to be cancelled
   * @PATCH request
   */
  cancelOrderPreview(id, email) {
    return instance.post(`/api/cancel-order-confirm/${id}/`, {
      email: email || AuthService.currentUser.data?.email,
    });
  }

  /**
   * @description User level endpoint for cancelling Orders
   * @PATCH request
   */
  cancelOrder(id, email) {
    return instance.patch(`/api/cancel-order/${id}/`, {
      email: email || AuthService.currentUser.data?.email,
      time_collected: new Date().toISOString(),
    });
  }
}

export default new UserService();
